import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Box, Link, renderData } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { SubHeader } from '../VerifyCM'
import RcmLayout from '../../components/rcm/RcmLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import CustomLabel from '../../components/InputFields/CustomLabel'
import { Text } from '../../components/RiskAssesmentPreview'
import RcmRiskMatrix from './RcmRiskMatrix'
import {
  HIGH_RISK_COLOR,
  LOW_RISK_COLOR,
  MEDIUM_RISK_COLOR,
  NO_RISK_COLOR
} from '../../components/constants/riskMatrixColors'
import { RcmBasicDetails } from './RcmViewStage1'

const healthRiskMatrix = [
  {
    category: 'Multiple Fatality',
    1: { text: '15', value: 'S5/P1', fieldValue: 'Multiple Fatality' },
    2: { text: '19', value: 'S5/P2', fieldValue: 'Multiple Fatality' },
    3: { text: '22', value: 'S5/P3', fieldValue: 'Multiple Fatality' },
    4: { text: '24', value: 'S5/P4', fieldValue: 'Multiple Fatality' },
    5: { text: '25', value: 'S5/P5', fieldValue: 'Multiple Fatality' }
  },
  {
    category: 'Single Fatality',
    1: { text: '10', value: 'S4/P1', fieldValue: 'Single Fatality' },
    2: { text: '14', value: 'S4/P2', fieldValue: 'Single Fatality' },
    3: { text: '18', value: 'S4/P3', fieldValue: 'Single Fatality' },
    4: { text: '21', value: 'S4/P4', fieldValue: 'Single Fatality' },
    5: { text: '23', value: 'S4/P5', fieldValue: 'Single Fatality' }
  },
  {
    category: 'Major Injury',
    1: { text: '6', value: 'S3/P1', fieldValue: 'Major Injury' },
    2: { text: '9', value: 'S3/P2', fieldValue: 'Major Injury' },
    3: { text: '13', value: 'S3/P3', fieldValue: 'Major Injury' },
    4: { text: '17', value: 'S3/P4', fieldValue: 'Major Injury' },
    5: { text: '20', value: 'S3/P5', fieldValue: 'Major Injury' }
  },
  {
    category: 'Minor Injury',
    1: { text: '3', value: 'S2/P1', fieldValue: 'Minor Injury' },
    2: { text: '5', value: 'S2/P2', fieldValue: 'Minor Injury' },
    3: { text: '8', value: 'S2/P3', fieldValue: 'Minor Injury' },
    4: { text: '12', value: 'S2/P4', fieldValue: 'Minor Injury' },
    5: { text: '16', value: 'S2/P5', fieldValue: 'Minor Injury' }
  },
  {
    category: 'Slight Injury',
    1: { text: '1', value: 'S1/P1', fieldValue: 'Slight Injury' },
    2: { text: '2', value: 'S1/P2', fieldValue: 'Slight Injury' },
    3: { text: '4', value: 'S1/P3', fieldValue: 'Slight Injury' },
    4: { text: '7', value: 'S1/P4', fieldValue: 'Slight Injury' },
    5: { text: '11', value: 'S1/P5', fieldValue: 'Slight Injury' }
  }
]

const environmentRiskMatrix = [
  {
    category: 'Massive Effect',
    1: { text: '15', value: 'S5/P1', fieldValue: 'Massive Effect' },
    2: { text: '19', value: 'S5/P2', fieldValue: 'Massive Effect' },
    3: { text: '22', value: 'S5/P3', fieldValue: 'Massive Effect' },
    4: { text: '24', value: 'S5/P4', fieldValue: 'Massive Effect' },
    5: { text: '25', value: 'S5/P5', fieldValue: 'Massive Effect' }
  },
  {
    category: 'Major Effect',
    1: { text: '10', value: 'S4/P1', fieldValue: 'Major Effect' },
    2: { text: '14', value: 'S4/P2', fieldValue: 'Major Effect' },
    3: { text: '18', value: 'S4/P3', fieldValue: 'Major Effect' },
    4: { text: '21', value: 'S4/P4', fieldValue: 'Major Effect' },
    5: { text: '23', value: 'S4/P5', fieldValue: 'Major Effect' }
  },
  {
    category: 'Localised Effect',
    1: { text: '6', value: 'S3/P1', fieldValue: 'Localised Effect' },
    2: { text: '9', value: 'S3/P2', fieldValue: 'Localised Effect' },
    3: { text: '13', value: 'S3/P3', fieldValue: 'Localised Effect' },
    4: { text: '17', value: 'S3/P4', fieldValue: 'Localised Effect' },
    5: { text: '20', value: 'S3/P5', fieldValue: 'Localised Effect' }
  },
  {
    category: 'Minor Effect',
    1: { text: '3', value: 'S2/P1', fieldValue: 'Minor Effect' },
    2: { text: '5', value: 'S2/P2', fieldValue: 'Minor Effect' },
    3: { text: '8', value: 'S2/P3', fieldValue: 'Minor Effect' },
    4: { text: '12', value: 'S2/P4', fieldValue: 'Minor Effect' },
    5: { text: '16', value: 'S2/P5', fieldValue: 'Minor Effect' }
  },
  {
    category: 'Slight Effect',
    1: { text: '1', value: 'S1/P1', fieldValue: 'Slight Effect' },
    2: { text: '2', value: 'S1/P2', fieldValue: 'Slight Effect' },
    3: { text: '4', value: 'S1/P3', fieldValue: 'Slight Effect' },
    4: { text: '7', value: 'S1/P4', fieldValue: 'Slight Effect' },
    5: { text: '11', value: 'S1/P5', fieldValue: 'Slight Effect' }
  }
]

export const mtbfOptions: any = {
  '0.2 Years': 0.2,
  '0.3 Years': 0.3,
  '0.6 Years': 0.6,
  '1 Year': 1,
  '1.5 Years': 1.5,
  '2 Years': 2,
  '2.5 Years': 2.5,
  '3 Years': 3,
  '3.5 Years': 3.5,
  '4 Years': 4,
  '20 Years': 20,
  '30 Years': 30
}

const calculatePof = (key: any) => {
  if (key < 0.3) {
    return 'Extreme (<.3 Years)'
  } else if (key > 0.3 && key <= 0.6) {
    return 'High (< .6 Years)'
  } else if (key > 0.6 && key < 4) {
    return 'Medium (0.6 - 4 Years)'
  } else if (key >= 4 && key < 30) {
    return 'Low (>20 Y)'
  } else if (key >= 30) {
    return 'Negligible (>30 Years)'
  } else {
    return '' // Default case
  }
}

const riskMatrix: any = {
  'Extreme Effect': [15, 19, 22, 24, 25],
  'High Effect': [10, 14, 18, 21, 23],
  'Medium Effect': [6, 9, 13, 17, 20],
  'Low Effect': [3, 5, 8, 12, 16],
  'Negligible Effect': [1, 2, 4, 7, 11]
}

const pofMapping: any = {
  'Extreme (<.3 Years)': 1,
  '20 Years': 2,
  '4 Years': 3,
  '3.5 Years': 3,
  '3 Years': 3,
  '2.5 Years': 3,
  '2 Years': 3,
  '1.5 Years': 3,
  '1 Year': 3,
  '0.6 Years': 4,
  '0.3 Years': 5,
  '0.2 Years': 5
}

const CalculateProductionLoss = ({
  handleChange,
  isOpen,
  setIsOpen,
  pof
}: {
  handleChange: any
  isOpen: any
  setIsOpen: any
  pof: any
}) => {
  const [values, setValue]: any = useState({
    productionLoss: '',
    howLong: 0,
    hourlyLoss: 0,
    failureLossHours: 0,
    spareCost: 0,
    labourCost: 0,
    externalCost: 0
  })

  const calulate = () => {
    const {
      howLong,
      hourlyLoss,
      failureLossHours,
      spareCost,
      labourCost,
      externalCost
    } = values

    const hours = Number(failureLossHours) - Number(howLong)
    const additionalCosts =
      Number(spareCost) + Number(labourCost) + Number(externalCost)
    const total = hours * Number(hourlyLoss) + additionalCosts

    return total
  }

  const calculateCategory = () => {
    const loss = calulate()

    if (loss < 100000) {
      return 'Negligble Effect'
    } else if (loss > 100000 && loss < 10000000) {
      return 'Low Effect'
    } else if (loss > 10000000 && loss < 100000000) {
      return 'Medium Effect'
    } else if (loss > 100000000 && loss < 1000000000) {
      return 'High Effect'
    } else if (loss > 100000000) {
      return 'Extreme Effect'
    }
  }

  const calculateScore = () => {
    const loss = calulate()

    if (loss < 100000) {
      return '5'
    } else if (loss > 100000 && loss < 10000000) {
      return '17'
    } else if (loss > 10000000 && loss < 100000000) {
      return '18'
    } else if (loss > 100000000 && loss < 1000000000) {
      return '24'
    } else if (loss > 100000000) {
      return '25'
    }
  }

  const getRiskMatrixValue = (selectedPof: any) => {
    const category: any = calculateCategory()
    const pofColumn = pofMapping[selectedPof] // Get the column based on POF
    const matrixRow = riskMatrix[category] // Get the row based on economic category

    // Return the intersected value from the matrix
    return matrixRow[pofColumn - 1]
  }

  return (
    <>
      <Modal size="lg" isOpen={isOpen}>
        <ModalBody>
          <SubHeader header="Calculate Production Loss" permitColor="gold" />
          <CustomLabel label="Is there any Production Loss ?" />
          <div className="d-flex align-items-center">
            <Input
              type="radio"
              value="Yes"
              name="a"
              onChange={(e: any) => {
                setValue({ ...values, productionLoss: e?.target?.value })
              }}
            />
            <Spacer width={5} />
            <Text> Yes</Text>
            <Spacer width={10} />
            <Input
              type="radio"
              value="No"
              name="a"
              onChange={(e: any) => {
                setValue({ ...values, productionLoss: e?.target?.value })
              }}
            />
            <Spacer width={5} />
            <Text>No</Text>
          </div>
          {values?.productionLoss === 'Yes' && (
            <>
              <CustomLabel label="How long it can tolerate without production Loss in Hours" />
              <Input
                type="text"
                onChange={(e: any) => {
                  setValue({ ...values, howLong: e?.target?.value })
                }}
              />
              <CustomLabel label="Hourly production Loss in USD" />
              <Input
                type="text"
                onChange={(e: any) => {
                  setValue({ ...values, hourlyLoss: e?.target?.value })
                }}
              />
              <CustomLabel label="Due to this Failure mode how long the machine can be in out of production in Hours" />
              <Input
                type="text"
                onChange={(e: any) => {
                  setValue({ ...values, failureLossHours: e?.target?.value })
                }}
              />
              <CustomLabel label="Due this failre mode what is the repair cost" />
              <div className="d-flex">
                <div>
                  <CustomLabel label="Cost of Spare (USD)" />
                  <Input
                    type="text"
                    onChange={(e: any) => {
                      setValue({ ...values, spareCost: e?.target?.value })
                    }}
                  />
                </div>
                <Spacer width={5} />
                <div>
                  <CustomLabel label="Cost of Labour (USD)" />
                  <Input
                    type="text"
                    onChange={(e: any) => {
                      setValue({ ...values, labourCost: e?.target?.value })
                    }}
                  />
                </div>
                <Spacer width={5} />
                <div>
                  <CustomLabel label="Cost of External Services (USD)" />
                  <Input
                    type="text"
                    onChange={(e: any) => {
                      setValue({ ...values, externalCost: e?.target?.value })
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <Spacer height={20} />
          <p>
            Economic Value:{' '}
            <strong>
              {values?.productionLoss === 'Yes' ? (
                <>
                  {calulate()} ({calculateCategory()})
                </>
              ) : (
                <>0 (Negligible Effect)</>
              )}
            </strong>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            onClick={() => {
              values?.productionLoss === 'Yes'
                ? handleChange(
                    calulate(),
                    getRiskMatrixValue(pof || '30 Years'),
                    calculateCategory()
                  )
                : handleChange(0, 1, 'Negligible Effect')

              setIsOpen(false)
              setValue({
                productionLoss: '',
                howLong: 0,
                hourlyLoss: 0,
                failureLossHours: 0,
                spareCost: 0,
                labourCost: 0,
                externalCost: 0
              })
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default function RcmViewStage5() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchRcm', fetchRcm)

  const fetchRiskMatrix = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/riskMatrix`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data: riskData }: any = useQuery('fetchRiskMatrix', fetchRiskMatrix)
  const riskMatrixData = riskData?.riskMatrix?.[0]
  const riskToleranceLevel = Number(riskMatrixData?.riskToleranceLevel) || 16

  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})
  const [isOpen, setIsOpen]: any = useState(false)

  const currentAnalysisData = data?.analysis
  const hasCurrentRcm = currentAnalysisData?.number
  const failureModes = currentAnalysisData?.failuresModes || []

  const [entries, setEntries] = useState(
    failureModes.reduce((acc: any, mode: any) => {
      acc[mode.name] = mode.failures.map((failure: any) => ({
        functionalFailure: failure.functionalFailure || '',
        failureMode: failure.failureMode || '',
        failureEffect: failure.failureEffect || '',
        healthSafety: failure.healthSafety || '',
        environment: failure.environment || '',
        pof: failure.pof || '',
        economic: failure.economic || '',
        loss: failure.loss || '',
        riskL: failure.riskL || '',
        riskR: failure.riskR || ''
      }))
      return acc
    }, {})
  )

  useEffect(
    () => {
      setEntries(
        failureModes.reduce((acc: any, mode: any) => {
          acc[mode.name] = mode.failures.map((failure: any) => ({
            functionalFailure: failure.functionalFailure || '',
            failureMode: failure.failureMode || '',
            failureEffect: failure.failureEffect || '',
            healthSafety: failure.healthSafety || '',
            environment: failure.environment || '',
            pof: failure.pof || '',
            economic: failure.economic || '',
            loss: failure.loss || '',
            riskL: failure.riskL || '',
            riskR: failure.riskR || ''
          }))
          return acc
        }, {})
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    data?.analysis?.failuresModes
  )

  const sortEntried = () => {
    const sortedEntries = { ...entries }

    Object.keys(sortedEntries).forEach((key) => {
      sortedEntries[key] = sortedEntries[key].sort(
        (a: any, b: any) => Number(b.riskScore) - Number(a.riskScore)
      )
    })

    setEntries(sortedEntries)
  }

  const handleInputChange = (
    categoryKey: any,
    index: any,
    field: any,
    value: any
  ) => {
    const updatedEntries = [...entries[categoryKey]]
    updatedEntries[index][field] = value
    setEntries((prev: any) => ({ ...prev, [categoryKey]: updatedEntries }))
  }

  const calculateScore = (eScore: any, hScore: any, ecScore: any) =>
    Number(eScore) + Number(hScore) + Number(ecScore)

  const CalcualteRisk = ({
    eScore,
    hScore,
    ecScore
  }: {
    eScore: any
    hScore: any
    ecScore: any
  }) => {
    const score = calculateScore(eScore, ecScore, hScore)

    const getColor = () => {
      if (score >= 1 && score <= 15) {
        return NO_RISK_COLOR
      } else if (score >= 16 && score <= 35) {
        return LOW_RISK_COLOR
      } else if (score >= 36 && score <= 50) {
        return MEDIUM_RISK_COLOR
      } else if (score >= 51 && score <= 75) {
        return HIGH_RISK_COLOR
      } else {
        return '#fff'
      }
    }

    return (
      <Col
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 60,
          backgroundColor: getColor(),
          cursor: 'pointer',
          color: '#fff'
        }}
      >
        {score > 0 && score}
      </Col>
    )
  }

  const RenderEconomic = ({ score, value }: { value: any; score: any }) => {
    const getColor = () => {
      if (score >= 1 && score <= 6) {
        return NO_RISK_COLOR
      } else if (score > 7 && score <= 15) {
        return MEDIUM_RISK_COLOR
      } else if (score > 16 && score <= 25) {
        return HIGH_RISK_COLOR
      } else {
        return '#fff'
      }
    }

    return (
      <Col
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          minHeight: 60,
          height: 'auto',
          backgroundColor: getColor(),
          cursor: 'pointer',
          color: '#fff'
        }}
      >
        {value}
      </Col>
    )
  }

  const CalculateTolrableRisk = ({
    eScore,
    hScore,
    ecScore
  }: {
    eScore: any
    hScore: any
    ecScore: any
  }) => {
    const score = calculateScore(eScore, ecScore, hScore)
    const getColor = () => {
      if (score > riskToleranceLevel) {
        return HIGH_RISK_COLOR
      } else if (score <= riskToleranceLevel) {
        return NO_RISK_COLOR
      }
    }

    const isTolerable = () => {
      if (score > riskToleranceLevel) {
        return 'Non Tolerable Risk'
      } else if (score <= riskToleranceLevel) {
        return 'Tolerable Risk'
      }
    }

    return (
      <Col
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 60,
          backgroundColor: getColor(),
          color: '#fff',
          cursor: 'pointer'
        }}
      >
        {isTolerable()}
      </Col>
    )
  }

  const updateRiskScore = (
    enScore: any,
    ecScore: any,
    hScore: any,
    mode: any,
    index: any
  ) => {
    const score = calculateScore(enScore, ecScore, hScore)
    handleInputChange(mode.name, index, 'riskScore', score)
  }

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15 }}>
        <SubHeader
          header={`${hasCurrentRcm ? currentAnalysisData?.number : ''}`}
          permitColor="gold"
        />
        <Spacer height={20} />
        <RcmBasicDetails data={currentAnalysisData} />
        <Spacer height={20} />
        <Box heading="Functions">
          {failureModes.map((mode: any, modeIndex: number) => (
            <div key={modeIndex}>
              <h4>{mode.name}</h4>
              <p>
                <strong>Associated Tags:</strong>{' '}
                {mode?.tags?.map((a: any) => a?.equipmentNumber)?.join(', ')}{' '}
              </p>
              <Table bordered style={{ borderColor: '#000' }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Functional Failure</th>
                    <th>Failure modes</th>
                    <th>Failure Effect</th>
                    <th>MTBF</th>
                    <th>POF</th>
                    <th>Health / Safety</th>
                    <th>Score</th>
                    <th>Environment</th>
                    <th>Score</th>
                    <th>Economic</th>
                    <th>Risk</th>
                    <th>Tolerable Level</th>
                  </tr>
                </thead>
                <tbody>
                  {entries[mode.name]?.map((entry: any, index: number) => (
                    <tr key={index}>
                      <td style={{ width: '5%' }}>{index + 1}</td>
                      <td style={{ width: '10%' }}>
                        {entry.functionalFailure}
                      </td>
                      <td style={{ width: '10%' }}>{entry.failureMode}</td>
                      <td style={{ width: '10%' }}>{entry.failureEffect}</td>
                      <td style={{ width: '10%' }}>
                        <Input
                          type="select"
                          onChange={(e) => {
                            handleInputChange(
                              mode.name,
                              index,
                              'mtbf',
                              e?.target?.value
                            )

                            handleInputChange(
                              mode.name,
                              index,
                              'pof',
                              calculatePof(mtbfOptions[e?.target?.value])
                            )
                          }}
                        >
                          <option>Select</option>
                          {Object.keys(mtbfOptions)?.map((h: any) => (
                            <option key={h}>{h}</option>
                          ))}
                        </Input>
                      </td>
                      <td style={{ width: '10%' }}>
                        {entry?.pof}
                        {/* <Input
                          type="select"
                          onChange={(e) => {
                            handleInputChange(
                              mode.name,
                              index,
                              'pof',
                              e?.target?.value
                            )
                          }}
                        >
                          <option>Select</option>
                          {[
                            'Extreme (<.3years)',
                            'HIGH ( < .6 years)',
                            'Medium (0.5 - 4 Years)',
                            'LOW(>20 Y)',
                            'Negligible(>30 Y)'
                          ]?.map((h: any) => <option key={h}>{h}</option>)}
                        </Input> */}
                      </td>
                      <td style={{ width: '10%' }}>
                        <RcmRiskMatrix
                          values={entry?.healthSafety}
                          score={entry?.healthSafetyScore}
                          riskMatrix={healthRiskMatrix}
                          onChange={(value: any, text: any) => {
                            handleInputChange(
                              mode.name,
                              index,
                              'healthSafety',
                              text
                            )
                            handleInputChange(
                              mode.name,
                              index,
                              'healthSafetyScore',
                              value
                            )
                            updateRiskScore(
                              entry?.environmentScore,
                              entry?.economicScore,
                              entry?.healthSafetyScore,
                              mode,
                              index
                            )
                          }}
                        />
                      </td>
                      <td className="text-center">
                        {entry?.healthSafetyScore}
                      </td>
                      <td style={{ width: '10%' }}>
                        <RcmRiskMatrix
                          values={entry?.environment}
                          score={entry?.environmentScore}
                          riskMatrix={environmentRiskMatrix}
                          onChange={(value: any, text: any) => {
                            handleInputChange(
                              mode.name,
                              index,
                              'environment',
                              text
                            )
                            handleInputChange(
                              mode.name,
                              index,
                              'environmentScore',
                              value
                            )
                            updateRiskScore(
                              entry?.environmentScore,
                              entry?.economicScore,
                              entry?.healthSafetyScore,
                              mode,
                              index
                            )
                          }}
                        />
                      </td>
                      <td className="text-center">{entry?.environmentScore}</td>
                      <td
                        style={{ width: '10%' }}
                        onClick={() =>
                          setIsOpen({ mode: mode, index: index, entry: entry })
                        }
                      >
                        {entry?.econonmic ? (
                          <RenderEconomic
                            score={entry?.economicScore}
                            value={
                              <>
                                <p className="m-0">{entry?.econonmic}</p>
                                <p className="m-0">
                                  Value = {entry?.economicEffect}
                                </p>
                                <p className="m-0">
                                  Score = {entry?.economicScore}
                                </p>
                              </>
                            }
                          />
                        ) : (
                          <Link
                            onClick={() =>
                              setIsOpen({
                                mode: mode,
                                index: index,
                                entry: entry
                              })
                            }
                          >
                            Calculate Economic
                          </Link>
                        )}
                      </td>
                      <td>
                        <CalcualteRisk
                          ecScore={entry?.environmentScore}
                          hScore={entry?.healthSafetyScore}
                          eScore={entry?.economicScore}
                        />
                      </td>

                      <td>
                        <CalculateTolrableRisk
                          ecScore={entry?.environmentScore}
                          hScore={entry?.healthSafetyScore}
                          eScore={entry?.economicScore}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ))}
        </Box>
        {currentAnalysisData?.status === 'Stage5' && (
          <ModalFooter>
            <Button color="warning" onClick={() => sortEntried()}>
              Sort by Tolerance
            </Button>
            <Button color="warning" onClick={() => setSubmit(true)}>
              Submit
            </Button>
          </ModalFooter>
        )}
        <CalculateProductionLoss
          isOpen={isOpen}
          pof={isOpen?.entry?.pof}
          setIsOpen={(e: any) => setIsOpen(e)}
          handleChange={async (loss: any, score: any, category: any) => {
            await handleInputChange(
              isOpen?.mode?.name,
              isOpen?.index,
              'economicEffect',
              loss
            )
            await handleInputChange(
              isOpen?.mode?.name,
              isOpen?.index,
              'economicScore',
              Number(score)
            )
            await handleInputChange(
              isOpen?.mode?.name,
              isOpen?.index,
              'econonmic',
              category
            )

            await updateRiskScore(
              isOpen?.entry?.environmentScore,
              Number(score),
              isOpen?.entry?.healthSafetyScore,
              isOpen?.mode,
              isOpen?.index
            )
          }}
        />
        <SubmitModalWithForm
          isOpen={submit}
          onClose={(val: any) => {
            setSubmit(false)
          }}
          onSubmit={async (e: any) => {
            e?.preventDefault()

            const body: any = {
              ...currentAnalysisData,
              riskDetails: entries,
              status: 'Stage6'
            }

            const res = await makeAuthenticatedRequest(
              `${rcmApi}/rcmAnalysis/${id}`,
              'PATCH',
              body,
              accounts,
              instance
            )

            if (res.status === 'success') {
              await refetch()
              setResult(res?.analysis)
            }

            return res.status
          }}
          sucessView={<p>RCM Number: {result?.number}</p>}
          size="xs"
          header="Failure Modes"
        />
      </Row>
    </RcmLayout>
  )
}
