import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Box, renderData } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { SubHeader } from '../VerifyCM'
import RcmLayout from '../../components/rcm/RcmLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { Button, Input, ModalFooter, Row, Table } from 'reactstrap'
import { RcmBasicDetails } from './RcmViewStage1'

export default function RcmViewStage3() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchRcm', fetchRcm)
  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})

  const currentAnalysisData = data?.analysis
  const hasCurrentRcm = currentAnalysisData?.number

  const [equipment, setEquipment]: any = useState(
    currentAnalysisData?.equipmentList
  )

  useEffect(
    () => {
      setEquipment(currentAnalysisData?.equipmentList)
    },
    data?.analysis?.equipmentList
  )

  const handleDropdownChange = (e: any, index: any, key: any) => {
    const newEquipment = [...equipment]
    newEquipment[index][key] = e.target.value
    setEquipment(newEquipment)
  }

  const categorizeEquipment = () => {
    const categorized: any = {}

    equipment.forEach((item: any) => {
      const subSystemKey = item.subSystem || 'Uncategorized' // Use taxonomy as key or 'Uncategorized' if not set
      if (!categorized[subSystemKey]) {
        categorized[subSystemKey] = []
      }
      categorized[subSystemKey].push(item)
    })

    return categorized // Update the categorized equipment state
  }

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15 }}>
        <SubHeader
          header={`${hasCurrentRcm ? currentAnalysisData?.number : ''}`}
          permitColor="gold"
        />
        <Spacer height={20} />
        <RcmBasicDetails data={currentAnalysisData} />
        <Spacer height={20} />
        <Box heading="Define Functions">
          <Table bordered className="min-vh-50" style={{ borderColor: '#000' }}>
            <thead>
              <tr>
                {[
                  'S.No',
                  'System Name',
                  'System Number',
                  'Equipment Number',
                  'Criticality',
                  'System / Sub System',
                  'Taxonomy',
                  'Function'
                ]?.map((h: any) => <th key={h}>{h}</th>)}
              </tr>
            </thead>
            <tbody>
              {equipment?.map((e: any, Eindex: number) => (
                <tr key={e?.value}>
                  <td>{Eindex + 1}</td>
                  <td>{e?.systemName}</td>
                  <td>{e?.systemNumber}</td>
                  <td>{e?.equipmentNumber}</td>
                  <td>{e?.criticality}</td>
                  <td>
                    <Input
                      type="select"
                      onChange={(e) =>
                        handleDropdownChange(e, Eindex, 'subSystem')
                      }
                    >
                      <option>Select</option>
                      {[
                        'Centrifugal Compressor',
                        'Motor Drive',
                        'Lubrication System',
                        'Cooler',
                        'Control System',
                        'Vibration Sensors'
                      ]?.map((o: any) => <option key={o}>{o}</option>)}
                    </Input>
                  </td>
                  <td>
                    <Input
                      type="select"
                      onChange={(e) =>
                        handleDropdownChange(e, Eindex, 'taxonomy')
                      }
                    >
                      <option>Select</option>
                      {[
                        'Industry',
                        'Business category',
                        'Installation',
                        'Plant Unit',
                        'Section/System',
                        'Equipment Unit',
                        'Sub unit',
                        'Component/Maintainable item',
                        'Part'
                      ]?.map((o: any) => <option key={o}>{o}</option>)}
                    </Input>
                  </td>
                  <td>
                    <Input
                      type="text"
                      onChange={(e) =>
                        handleDropdownChange(e, Eindex, 'function')
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
        {currentAnalysisData?.status === 'Stage3' && (
          <ModalFooter>
            <Button color="warning" onClick={() => setSubmit(true)}>
              Submit
            </Button>
          </ModalFooter>
        )}
        <SubmitModalWithForm
          isOpen={submit}
          onClose={(val: any) => {
            setSubmit(false)
          }}
          onSubmit={async (e: any) => {
            e?.preventDefault()
            const body: any = {
              equipmentList: equipment,
              categorizedEquipment: categorizeEquipment(),
              status: 'Stage4'
            }
            const res = await makeAuthenticatedRequest(
              `${rcmApi}/rcmAnalysis/${id}`,
              'PATCH',
              body,
              accounts,
              instance
            )

            if (res.status === 'success') {
              await refetch()
              setResult(res?.analysis)
            }

            return res.status
          }}
          sucessView={<p>RCM Number: {result?.number}</p>}
          size="xs"
          header="Define Function"
        />
      </Row>
    </RcmLayout>
  )
}
