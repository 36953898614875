import { useMsal } from '@azure/msal-react'
import axios from 'axios'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import { getPermissions } from '../components/lib/permissions'
import toNormalCase from '../components/lib/toNormalCase'
import Loader from '../components/Loader'
import CloseCMPermit from '../components/Modals/CloseCMPermit'
import ModalTitle from '../components/ModalTitle'
import PermitHeaderDetails, {
  renderTitle
} from '../components/PermitHeaderDetails'
import { Text } from '../components/RiskAssesmentPreview'
import { createCMMSWO, getAllCMMSWO } from '../components/serverurl'
import Spacer from '../components/Spacer'

const INITIAL_STATE = {
  title: 'Are you sure you want to submit?',
  icon: null,
  details: null
}

export const renderRow = (title: string, content?: any, color = '#fefaa2') => (
  <div>
    <Row>
      <Col
        xs="3"
        style={{
          backgroundColor: color,
          WebkitPrintColorAdjust: 'exact',
          fontSize: 14
        }}
      >
        {renderTitle(title)}
      </Col>
      <Col xs="9">
        <Text>{content || 'NA'}</Text>
      </Col>
    </Row>
  </div>
)

export const rowStyle: any = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'auto',
  paddingTop: 5,
  paddingBottom: 5,
  fontSize: 16,
  WebkitPrintColorAdjust: 'exact',
  backgroundColor: '#fefaa2',
  marginTop: 10,
  marginBottom: 10
}

export const SubHeader = ({ header, permitColor }: any) => (
  <Row
    style={{
      ...rowStyle,
      backgroundColor: permitColor || rowStyle?.backgroundColor
    }}
  >
    <p style={{ margin: 0, lineHeight: 2, textAlign: 'center' }}>
      <b>{header}</b>
    </p>
  </Row>
)

export const CMPreview = ({ data }: any) => {
  const fields = {
    'Work Order Number': data?.woNumber,
    'Work Order Title': data?.title,
    'Created On': moment(data?.createdAt).format('DD/MM/YYYY'),
    'Due Finish Date': moment(data?.createdAt).format('DD/MM/YYYY'),
    'Responsible Department': data?.respDiscipline
  }

  const permitData: any = {
    Equipment: data?.equipment,
    'Equipment Description': data?.equipmentDescription,
    'Tag Number': data?.tagNumber,
    'Tag Description': data?.tagNumberDescription,
    Reason: data?.reason,
    Location: data?.location,
    Status: toNormalCase(data?.status),
    'System Criticality': data?.systemCriticality,
    'Vendor Required': data?.vendorRequired,
    'P&ID': data?.pAndId,
    'Need Shutdown': data?.needShutdown,
    'Work Order Category': data?.woCategory,
    'Maintenance Class': data?.mainClass,
    'Maintenance Cause': data?.mainCause,
    Priority: data?.priority,
    'Estimated Duration': data?.estimatedDuration,
    'Created By': data?.createdBy,
    'Planning Date': moment(data?.planningDate).format('DD/MM/YYYY'),
    'DueFinish Date': moment(data?.dueFinishDate).format('DD/MM/YYYY'),
    'Schedule Start': moment(data?.schStart).format('DD/MM/YYYY'),
    'Schedule Finish': moment(data?.schFinish).format('DD/MM/YYYY')
  }

  return (
    <>
      {/* <SectionHeader title="CORRECTIVE MAINTENANCE PERMIT DETAILS" /> */}
      <Spacer height={10} />
      <ModalTitle
        title={`${data?.woNumber} - ${toNormalCase(
          data?.status
        ).toUpperCase()}`}
      />
      <PermitHeaderDetails permit={fields} />
      <Spacer height={10} />
      <SubHeader header="Permit Details" />
      <Spacer height={10} />
      {Object.keys(permitData).map((key) => renderRow(key, permitData[key]))}
    </>
  )
}

export default function VerifyCM({
  currentStatus,
  nextStatus,
  canClose,
  showWaiting
}: {
  currentStatus?: String
  nextStatus?: string | any
  canClose?: boolean
  showWaiting?: boolean
}) {
  const [data, setData]: any = useState()
  const [selected, setSeleced]: any = useState([])
  const [submitting, setSubmitting]: any = useState(false)
  const [message, setMessge]: any = useState(INITIAL_STATE)
  const [closeModal, setCloseModal]: any = useState(false)
  const [permissions, setPermissions]: any = useState()
  const { accounts, instance } = useMsal()

  const fetchPermits = async () => {
    try {
      await axios
        .get(`${getAllCMMSWO}?status=${currentStatus}`)
        .then((response: any) => {
          setData(response?.data?.data?.permits)
          setSeleced(response?.data?.data?.permits[0])
        })
    } catch (err) {
      console.warn(err)
    }
  }

  useEffect(() => {
    fetchPermits()
    if (!permissions) {
      getPermissions(accounts, instance)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!data) {
    return <Loader />
  }

  const onSubmit = async (next: any) => {
    try {
      setSubmitting(true)
      await axios
        .patch(
          `${createCMMSWO}?status=${
            next ? next : nextStatus
          }&id=${selected?._id}`
        )
        .then(async (response: any) => {
          const data = response?.data?.data?.permit
          setMessge({
            title:
              response?.data?.status === 'success'
                ? 'Verified Successfully'
                : 'Unable to Verify',
            icon: response?.data?.status,
            details:
              response?.data?.status === 'success'
                ? {
                    'WO Number': data?.woNumber,
                    Title: data?.title,
                    Status: data?.status
                  }
                : null
            // response?.data?.data?.permit
          })
          await fetchPermits()
          setSubmitting(false)
        })
    } catch (error) {
      console.warn(error)
      return { satus: false }
    }
  }

  if (data?.length === 0) {
    return (
      <ProtectedLayout>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '50vh'
          }}
        >
          <p>No Permits to be Verified</p>
        </div>
      </ProtectedLayout>
    )
  }

  if (!data) {
    return <Loader />
  }

  const SubmitButton = ({ updateStatusTo, children }: any) => {
    return (
      <Button
        style={{ width: 'inherit' }}
        color="success"
        onClick={() => onSubmit({ updateStatusTo })}
        disabled={submitting}
      >
        {children}
      </Button>
    )
  }

  return (
    <ProtectedLayout
      hasSideList
      allPermits={data}
      selectedPermit={selected}
      setSelectedPermit={setSeleced}
      onBack="/cmms/dashboard"
    >
      {/* <CMPreview data={selected} /> */}
      <p>asfgasfgasfasf</p>
      {permissions?.canVerifyCM && (
        <SubmitButton updateStatusTo="verified">Verify</SubmitButton>
      )}
      {!canClose && (
        <Col xs="12">
          <Button
            style={{ width: 'inherit' }}
            color="success"
            onClick={() => onSubmit(nextStatus)}
            disabled={submitting}
          >
            {toNormalCase(nextStatus)}
          </Button>
        </Col>
      )}
      {showWaiting && (
        <Row>
          <Spacer height={10} />
          <Button
            color="warning"
            onClick={() => onSubmit('waitingFor3rdParty')}
            disabled={submitting}
          >
            Wating for 3rd party
          </Button>
          <Spacer height={10} />
          <Button
            color="warning"
            onClick={() => onSubmit('waitingForMaterial')}
            disabled={submitting}
          >
            Wating for Material
          </Button>
          <Spacer height={10} />
          <Button
            color="warning"
            onClick={() => onSubmit('waitingForShutdown')}
            disabled={submitting}
          >
            Wating for Shutdown
          </Button>
        </Row>
      )}
      {canClose && (
        <Col xs="12">
          <Button
            style={{ width: 'inherit' }}
            color="success"
            onClick={() => setCloseModal(true)}
          >
            Close
          </Button>
        </Col>
      )}
      {/* <SubmitModal
        isOpen={submit}
        toggle={() => setSubmit(!submit)}
        onSubmit={() => onSubmit()}
        refetch={() => fetchPermits()}
        submitModalContent={message}
        afterClose={() => setMessge(INITIAL_STATE)}
        size="lg"
      /> */}
      <CloseCMPermit
        isOpen={closeModal}
        toggle={() => setCloseModal(!closeModal)}
        permitId={selected?._id}
        fetchPermits={fetchPermits}
        type="CM"
      />
    </ProtectedLayout>
  )
}
