import ReactToPrint from 'react-to-print'
import { Button, ModalFooter, Row, Table } from 'reactstrap'
import { useRef, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Box, renderData } from '../workManagement/PmTree'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { rowStyle, SubHeader } from '../VerifyCM'
import RcmLayout from '../../components/rcm/RcmLayout'
import Spacer from '../../components/Spacer'
import { mtbfOptions } from './RcmViewStage5'
import './../../../src/components/css/print.css'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import {
  HIGH_RISK_COLOR,
  LOW_RISK_COLOR,
  MEDIUM_RISK_COLOR,
  NO_RISK_COLOR
} from '../../components/constants/riskMatrixColors'

const getColor = (score: any) => {
  if (Number(score) >= 1 && Number(score) <= 6) {
    return NO_RISK_COLOR
  } else if (Number(score) >= 7 && Number(score) <= 17) {
    return MEDIUM_RISK_COLOR
  } else if (Number(score) >= 18 && Number(score) <= 25) {
    return HIGH_RISK_COLOR
  } else {
    return 'gray'
  }
}

const getRiskColor = (score: any) => {
  if (score >= 1 && score <= 15) {
    return NO_RISK_COLOR
  } else if (score >= 16 && score <= 35) {
    return LOW_RISK_COLOR
  } else if (score >= 36 && score <= 50) {
    return MEDIUM_RISK_COLOR
  } else if (score >= 51 && score <= 75) {
    return HIGH_RISK_COLOR
  } else {
    return '#fff'
  }
}

const getToleranceColor = (score: any, riskToleranceLevel: any) => {
  if (score > riskToleranceLevel) {
    return HIGH_RISK_COLOR
  } else if (score <= riskToleranceLevel) {
    return NO_RISK_COLOR
  }
}

const isTolerable = (score: any, riskToleranceLevel: any) => {
  if (score > riskToleranceLevel) {
    return 'Non Tolerable Risk'
  } else if (score <= riskToleranceLevel) {
    return 'Tolerable Risk'
  }
}

export const RcmBasicDetails = ({ data }: { data: any }) => {
  const rcmDetails: any = {
    Unit: data?.unit || data?.plantUnit,
    System: data?.system,
    Location: data?.location,
    'Analysis Description': data?.analysisDescription,
    'Created On': getReadableTime(data?.createdAt)
  }
  return (
    <>
      <Box heading="RCM Basic Details">
        {Object.keys(rcmDetails)?.map(
          (k: any) => renderData(k, rcmDetails[k]),
          8
        )}
      </Box>

      <Box heading="Team Members">
        <Table
          bordered
          className="min-vh-50"
          style={{ borderColor: '#000', fontSize: 12 }}
        >
          <thead>
            <tr>
              {['S.No', 'Name', 'Emp No', 'Department', 'Designation']?.map(
                (h: any) => <th key={h}>{h}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {data?.team?.map((e: any, Eindex: number) => (
              <tr key={e?.value}>
                <td>{Eindex + 1}</td>
                <td>{e?.givenName}</td>
                <td>{e?.employeeId}</td>
                <td>{e?.department}</td>
                <td>{e?.jobTitle}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>
    </>
  )
}

export default function RcmAnalysisPrint() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchRcm', fetchRcm)

  const fetchRiskMatrix = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/riskMatrix`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data: riskData, isLoading }: any = useQuery(
    'fetchRiskMatrix',
    fetchRiskMatrix
  )
  const riskMatrixData = riskData?.riskMatrix?.[0]
  const riskToleranceLevel = Number(riskMatrixData?.riskToleranceLevel) || 16

  const [submit, setSubmit]: any = useState(false)

  const currentAnalysisData = data?.analysis
  const hasCurrentRcm = currentAnalysisData?.number
  const riskDetails = currentAnalysisData?.riskDetails || []
  const categorizedEquipment = currentAnalysisData?.categorizedEquipment
  const equipmentList = currentAnalysisData?.equipmentList
  const componentRef: any = useRef()

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <style>
        {`
          @media print {
            @page {
              size: landscape;
            }
          }
        `}
      </style>

      <div
        ref={componentRef}
        style={{
          overflow: 'hiddden',
          display: 'flex',
          justifyContent: 'space-around'
        }}
      >
        <Row style={{ margin: 15, maxWidth: '87vw' }}>
          <p
            style={{
              margin: 0,
              lineHeight: 2,
              textAlign: 'center',
              ...rowStyle,
              backgroundColor: 'gold'
            }}
          >
            <b>{`${hasCurrentRcm ? currentAnalysisData?.number : ''}`}</b>
          </p>
          <Spacer height={20} />
          <RcmBasicDetails data={currentAnalysisData} />
          <div style={{ pageBreakBefore: 'always', padding: 0 }}>
            <Spacer height={20} />
            <Box heading="System Boundary">
              <Table bordered style={{ borderColor: '#000', fontSize: 12 }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Eqipment Number</th>
                    <th>Function</th>
                    <th>System Name</th>
                    <th>System Number</th>
                    <th>Taxonomy</th>
                    <th>Criticality</th>
                  </tr>
                </thead>
                <tbody>
                  {equipmentList?.length > 0 &&
                    equipmentList?.map((e: any, Eindex: any) => (
                      <tr key={e?.equipmentNumber}>
                        <td>{Eindex + 1}</td>
                        <td>{e?.equipmentNumber}</td>
                        <td>{e?.function}</td>
                        <td>{e?.systemName}</td>
                        <td>{e?.systemNumber}</td>
                        <td>{e?.taxonomy}</td>
                        <td>{e?.criticality}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Box>
          </div>
          <div style={{ pageBreakBefore: 'always', padding: 0 }}>
            <Spacer height={20} />
            <Box heading="Tolerance and Non Tolernance">
              {Object.keys(riskDetails).map((mode: any, modeIndex: number) => (
                <div key={modeIndex}>
                  <h4>{mode}</h4>
                  <p>
                    <strong>Associated Tags:</strong>{' '}
                    {categorizedEquipment[mode]
                      ?.map((a: any) => a?.equipmentNumber)
                      ?.join(', ')}{' '}
                  </p>
                  <Table
                    bordered
                    responsive
                    hover
                    style={{
                      borderColor: '#000',
                      tableLayout: 'fixed',
                      fontSize: 10
                    }}
                  >
                    <thead>
                      <tr className="text-center">
                        <th
                          style={{
                            width: '2vw'
                          }}
                        >
                          No.
                        </th>
                        <th style={{ width: 200 }}>Functional Failure</th>
                        <th>Failure modes</th>
                        <th style={{ width: 200 }}>Failure Effect</th>
                        <th>MTBF</th>
                        <th>POF</th>
                        <th>Health / Safety</th>
                        <th>Environment</th>
                        <th>Economic</th>
                        <th>Risk Score</th>
                        <th>Tolerable Level</th>
                      </tr>
                    </thead>
                    <tbody>
                      {riskDetails[mode]?.map(
                        (entry: any, index: number) =>
                          Number(entry.riskScore) > riskToleranceLevel && (
                            <tr key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td style={{ textAlign: 'center' }}>
                                {entry.functionalFailure}
                              </td>
                              <td>{entry.failureMode}</td>
                              <td style={{ textAlign: 'center' }}>
                                {entry.failureEffect}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {entry.mtbf}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {entry.pof}
                              </td>
                              <td
                                style={{
                                  textAlign: 'center',
                                  backgroundColor: getColor(
                                    entry.healthSafetyScore
                                  )
                                }}
                              >
                                {entry.healthSafety}
                                <br />
                                Score - {entry.healthSafetyScore}
                              </td>
                              <td
                                style={{
                                  textAlign: 'center',
                                  backgroundColor: getColor(
                                    entry.environmentScore
                                  )
                                }}
                              >
                                {entry.environment}
                                <br />
                                Score - {entry.environmentScore}
                              </td>
                              <td
                                style={{
                                  textAlign: 'center',
                                  backgroundColor: getColor(entry.economicScore)
                                }}
                              >
                                {entry.econonmic}
                                <br />
                                Score - {entry.economicScore}
                                <br />
                                Value - {entry.economicEffect}
                              </td>
                              <td
                                style={{
                                  textAlign: 'center',
                                  backgroundColor: getRiskColor(entry.riskScore)
                                }}
                              >
                                {entry.riskScore}
                              </td>
                              <td
                                style={{
                                  textAlign: 'center',
                                  backgroundColor: getToleranceColor(
                                    entry.riskScore,
                                    riskToleranceLevel
                                  )
                                }}
                              >
                                {isTolerable(
                                  entry?.riskScore,
                                  riskToleranceLevel
                                )}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </Table>
                </div>
              ))}
            </Box>
          </div>

          <div style={{ pageBreakBefore: 'always', padding: 0 }}>
            <Spacer height={20} />
            <Box heading="Functions">
              {Object.keys(riskDetails).map((mode: any, modeIndex: number) => (
                <div key={modeIndex}>
                  <h4>{mode}</h4>
                  <p>
                    <strong>Associated Tags:</strong>{' '}
                    {categorizedEquipment[mode]
                      ?.map((a: any) => a?.equipmentNumber)
                      ?.join(', ')}{' '}
                  </p>
                  <Table
                    bordered
                    responsive
                    hover
                    style={{
                      borderColor: '#000',
                      tableLayout: 'fixed',
                      fontSize: 10
                    }}
                  >
                    <thead>
                      <tr className="text-center">
                        <th
                          style={{
                            width: '2vw'
                          }}
                        >
                          S.No
                        </th>
                        <th style={{ width: '12vw' }}>Failure modes</th>
                        <th style={{ width: '5vw' }}>Risk Score</th>
                        <th style={{ width: '12vw' }}>RCM Decision</th>
                        <th style={{ width: '8vw' }}>Decision Tree Code</th>
                        <th style={{ width: '12vw' }}>Client Decision</th>
                        <th style={{ width: '8vw' }}>Task Status</th>
                        <th style={{ width: '8vw' }}>Task Duration</th>
                        <th style={{ width: '8vw' }}>Task Interval</th>
                        <th style={{ width: '8vw' }}>
                          Extrenal Service Cost (USD)
                        </th>
                        <th style={{ width: '8vw' }}>Spare Cost (USD)</th>
                        <th style={{ width: '12vw' }}>Discipline 1</th>
                        <th style={{ width: '8vw' }}>Hours</th>
                        <th style={{ width: '12vw' }}>Discipline 2</th>
                        <th style={{ width: '8vw' }}>Hours</th>
                        <th style={{ width: '12vw' }}>Discipline 3</th>
                        <th style={{ width: '8vw' }}>Hours</th>
                        <th style={{ width: '8vw' }}>Yearly Economic Value</th>
                        <th
                          style={{
                            width: '8vw',
                            position: 'sticky',
                            backgroundColor: 'white',
                            zIndex: 1
                          }}
                        >
                          Yealy maintenance Cost
                        </th>

                        <th style={{ width: '8vw' }}>Ratio</th>
                        <th style={{ width: '8vw' }}>New Task Interval</th>
                        <th style={{ width: '8vw' }}>New Calculated Ratio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {riskDetails[mode]?.map(
                        (entry: any, index: number) =>
                          Number(entry.riskScore) > riskToleranceLevel && (
                            <tr key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td>{entry.failureMode}</td>
                              <td style={{ textAlign: 'center' }}>
                                {entry.riskScore}
                              </td>
                              <td
                                style={{
                                  verticalAlign: 'middle',
                                  textAlign: 'center'
                                }}
                              >
                                {entry?.rcmDecision}
                              </td>
                              <td className="text-center">
                                {entry?.rcmDecisionCode}
                              </td>
                              <td>{entry?.clientDecision}</td>
                              <td>{entry?.taskStatus}</td>
                              <td>{entry?.taskDuration}</td>
                              <td>{entry?.taskInterval}</td>
                              <td>{entry?.externalCost}</td>
                              <td>{entry?.spareCost}</td>
                              <td>{entry?.discipline1}</td>
                              <td>{entry?.discipline1Hours}</td>
                              <td>{entry?.discipline2}</td>
                              <td>{entry?.discipline2Hours}</td>
                              <td>{entry?.discipline3}</td>
                              <td>{entry?.discipline3Hours}</td>
                              <td>
                                {Number(entry?.economicEffect) ||
                                  0 * mtbfOptions[entry?.economicEffect] ||
                                  1}
                              </td>
                              <td>{entry?.yearlyMaintenance}</td>
                              <td>{entry?.ratio}</td>
                              <td>{entry?.newTaskInterval}</td>
                              <td>{entry?.newRatio}</td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </Table>
                </div>
              ))}
            </Box>
          </div>
          <div style={{ padding: 0 }}>
            <img
              src={require('./../../images/Icons/Decision Tree.jpeg')}
              alt="decision tree"
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        </Row>
      </div>
      {currentAnalysisData?.status === 'Stage6' && (
        <ReactToPrint
          documentTitle={hasCurrentRcm}
          trigger={() => (
            <ModalFooter style={{ marginTop: 10 }}>
              <Button color="warning" children="Print" />
            </ModalFooter>
          )}
          content={() => componentRef.current}
        />
      )}
    </RcmLayout>
  )
}
