import FolderTree from 'react-folder-tree'
import { BsFileEarmarkTextFill } from 'react-icons/bs'
import { PiFolderOpenLight, PiFolderSimpleFill } from 'react-icons/pi'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
  Table
} from 'reactstrap'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import 'react-folder-tree/dist/style.css'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { equipmentAPI, pmAPI } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { treeData } from './hierarchyData'
import ModalTitle from '../../components/ModalTitle'
import { useNavigate } from 'react-router-dom'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import CustomLabel from '../../components/InputFields/CustomLabel'
import { incrementby1 } from '../../components/Forms/JSAForm'

export const renderData = (key: any, value: any, fontSize = 14) => (
  <div
    style={{
      marginBottom: 2,
      width: '100%',
      fontSize: fontSize
    }}
  >
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <p
        style={{
          width: '30%',
          margin: 0,
          fontSize: fontSize,
          fontWeight: 500
        }}
      >
        {key}
      </p>
      <p
        style={{
          margin: 0,
          width: '70%',
          fontSize: fontSize,
          textOverflow: 'ellipsis'
        }}
      >
        {value}
      </p>
    </div>
  </div>
)

export const Link = ({ children, onClick = {}, size = 14 }: any) => {
  return (
    <>
      <a
        style={{
          color: 'blue',
          cursor: 'pointer',
          textDecoration: 'underline',
          fontSize: size
        }}
        onClick={onClick}
      >
        {children}
      </a>
      <br />
    </>
  )
}

export const Box = ({
  children,
  heading,
  height,
  headingCenter = false
}: {
  children: any
  heading: string
  height?: string | any
  headingCenter?: boolean
}) => {
  return (
    <div
      style={{
        borderRadius: 10,
        border: '1px solid #d3d3d3',
        padding: 0,
        marginBottom: 20
      }}
    >
      <div
        style={{
          backgroundColor: 'gold',
          WebkitPrintColorAdjust: 'exact',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          height: 50,
          padding: 10
        }}
      >
        <h4 style={{ textAlign: headingCenter ? 'center' : 'left' }}>
          {heading}
        </h4>
      </div>
      <div
        style={{
          padding: 10,
          minHeight: height || '5vh'
        }}
      >
        {children}
      </div>
    </div>
  )
}

export const BasicLoader = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'inherit'
      }}
    >
      <Spinner />
    </div>
  )
}

export const ViewDocument = ({
  isOpen,
  toggle,
  title,
  src
}: {
  isOpen: any
  toggle: any
  title: any
  src: any
}) => {
  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} size="xl">
      <ModalTitle title={title} />
      <ModalBody>
        <iframe
          title="Document Preview"
          src={src}
          width="100%"
          height="700px"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={() => toggle()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const ViewSpares = ({
  isOpen,
  toggle,
  equipment,
  spares
}: {
  isOpen: any
  toggle: any
  equipment: any
  spares: any
}) => {
  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} size="xl">
      <ModalTitle title={`Spares list for equipment ${equipment?.tag}`} />
      <ModalBody>
        <Table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Spare Part Number</th>
              <th>Serial part Description</th>
              <th>On Hand Quantity</th>
              <th>Cost Center</th>
              <th>Has Active PR</th>
              <th>Has Active PO</th>
            </tr>
          </thead>
          <tbody>
            {spares?.length > 0 ? (
              spares?.map((s: any, index: any) => (
                <tr key={s?._id}>
                  <td>{incrementby1(index)}</td>
                  <td>{s?.['Spare Part Number']}</td>
                  <td>{s?.['Spare Part Description']}</td>
                  <td>{s?.onHandQuantity}</td>
                  <td>{s?.costCenter}</td>
                  <td>{s?.hasActivePR || 'NA'}</td>
                  <td>{s?.hasActivePO || 'NA'}</td>
                </tr>
              ))
            ) : (
              <tr style={{ height: 300 }}>
                <td colSpan={12}>No Spares Found</td>
              </tr>
            )}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={() => toggle()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default function PMTree({ onBack }: { onBack: any }) {
  const { accounts, instance } = useMsal()

  const [select, setSelect]: any = useState()
  const [document, setDocument]: any = useState()
  const [updateCounter, setUpdateCounter]: any = useState()
  const [counterValue, setCounterValue]: any = useState()
  const [viewSpares, setViewSpares]: any = useState(false)

  const fetchTag = async () => {
    const response = await makeAuthenticatedRequest(
      `${equipmentAPI}?equipment=${select?.name}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, refetch, isRefetching }: any = useQuery(
    'tagDetails',
    fetchTag
  )

  const equipment = data?.equipment
  const spares = data?.spares

  const technicalDetails: any = {
    Type: equipment?.tagType,
    Model: equipment?.modelNo,
    'Serial Number': equipment?.serialNo,
    Manufacturer: equipment?.manufacturer,
    Supplier: equipment?.supplier,
    'Operation Status': equipment?.operationStatus,
    'Ex Rating': equipment?.exrating,
    'Class Code': equipment?.classCode,
    'Regulatory Ref': equipment?.regulatoryRef,
    Reference: equipment?.reference,
    'Drawing NO': equipment?.pid,
    Note: equipment?.note
  }

  const basicDetails: any = {
    Vessel: equipment?.vessel,
    System: equipment?.systemDescription,
    'Sub System': equipment?.subsystemDescription,
    Function: equipment?.functionDescription,
    Tag: equipment?.tag,
    'Tag Description': equipment?.tagDescription,
    'Criticality (text)': equipment?.criticality,
    Criticality: equipment?.criticality,
    Location: equipment?.location,
    'Performance Std': equipment?.performanceStd,
    'Cost Center': equipment?.costCenter
  }

  useEffect(() => {
    refetch()
  }, [select?.name])

  const onNameClick = ({ defaultOnClick, nodeData }: any) => {
    defaultOnClick()
    setSelect(nodeData)
  }

  const FolderIcon = ({ onClick: defaultOnClick }: any) => {
    return <PiFolderSimpleFill onClick={defaultOnClick} color="orange" />
  }

  const FolderOpenIcon = ({ onClick: defaultOnClick }: any) => {
    return <PiFolderOpenLight onClick={defaultOnClick} color="orange" />
  }

  const FileIcon = ({ onClick: defaultOnClick }: any) => {
    return <BsFileEarmarkTextFill onClick={defaultOnClick} color="lightblue" />
  }

  const customIdentPixels = 50
  const iconComponents = {
    FolderIcon,
    FolderOpenIcon,
    FileIcon
  }

  const navigate = useNavigate()

  return (
    <ProtectedLayout onBack={onBack} title="EQUIPMENT">
      <Row style={{ margin: 15, fontSize: 16 }}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%', marginRight: 10 }}>
            <Box heading="Hierarchy" height="76vh">
              <FolderTree
                onNameClick={onNameClick}
                data={treeData}
                iconComponents={iconComponents}
                indentPixels={customIdentPixels}
                showCheckbox={false}
                initOpenStatus="closed"
                readOnly
              />
            </Box>
          </div>

          <div style={{ width: '50%', marginLeft: 10 }}>
            <Box heading="Basic Details" height={'25vh'}>
              <>
                {isLoading || isRefetching ? (
                  <BasicLoader />
                ) : (
                  Object.keys(basicDetails)?.map((key: any) =>
                    renderData(key, basicDetails[key])
                  )
                )}
              </>
            </Box>
            <Box heading="Technical Details" height={'25vh'}>
              <>
                {isLoading || isRefetching ? (
                  <BasicLoader />
                ) : (
                  Object.keys(technicalDetails)?.map((key: any) =>
                    renderData(key, technicalDetails[key])
                  )
                )}
              </>
            </Box>

            <Box heading="Documentation Details" height={'10vh'}>
              <>
                {isLoading || isRefetching ? (
                  <BasicLoader />
                ) : (
                  <Row style={{ marginLeft: 40 }}>
                    {equipment?.hasCounter && equipment?.pmId && (
                      <Link onClick={() => setUpdateCounter(true)}>
                        Update Counter
                      </Link>
                    )}
                    <Link
                      onClick={() =>
                        navigate(`/equipment/cm/new?equipment=${select?.name}`)
                      }
                    >
                      Create Work Request
                    </Link>
                    <Link
                      onClick={() => {
                        setDocument(require('./../../documents/pid.pdf'))
                      }}
                    >
                      Pid
                    </Link>
                    <Link
                      onClick={() => {
                        setDocument(require('./../../documents/manual.pdf'))
                      }}
                    >
                      Manuals
                    </Link>
                    <Link
                      onClick={() => {
                        setDocument(require('./../../documents/otherdoc.pdf'))
                      }}
                    >
                      Other Technical Documents
                    </Link>
                    <Link>Calibration Sheets</Link>
                    <Link>Work History</Link>
                    <Link onClick={() => setViewSpares(true)}>View Spares</Link>
                  </Row>
                )}
              </>
            </Box>
          </div>
        </div>
      </Row>
      <ViewDocument
        isOpen={!!document}
        toggle={() => setDocument(!document)}
        src={document}
        title={'Document'}
      />
      {equipment && (
        <ViewSpares
          isOpen={viewSpares}
          toggle={() => setViewSpares(!viewSpares)}
          equipment={equipment}
          spares={spares}
        />
      )}
      <SubmitModalWithForm
        isOpen={updateCounter}
        onClose={(val: any) => {
          setUpdateCounter(false)
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const body: any = {
            currentRunningValue: counterValue
          }

          const res = await makeAuthenticatedRequest(
            `${pmAPI}/${equipment?.pmId}`,
            'PATCH',
            body,
            accounts,
            instance
          )

          if (res?.status === 'success') {
            refetch()
          }

          return res?.status
        }}
        size="xs"
        header="Update Counter"
        form={
          <Row>
            <Col>
              <CustomLabel label="Update Latest Running hours" />
              <Input
                type="text"
                onChange={(e: any) => setCounterValue(e?.target?.value)}
              />
            </Col>
          </Row>
        }
      />
    </ProtectedLayout>
  )
}
