import { Row, Spinner, Table } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import RcmLayout from '../../components/rcm/RcmLayout'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { Link } from '../workManagement/PmTree'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { rowStyle, SubHeader } from '../VerifyCM'
import { useEffect } from 'react'

export default function RcmAnalysisList({
  stage,
  title
}: {
  stage: any
  title: any
}) {
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis?stage=${stage}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch, isLoading, isRefetching }: any = useQuery(
    'fetchRcmList',
    fetchRcm
  )

  useEffect(() => {
    refetch()
  }, [stage])

  if (isLoading || isRefetching) {
    return (
      <RcmLayout onBack={'/cmms/rcm/dashboard'}>
        <Row style={{ margin: 15 }}>
          <p
            style={{
              margin: 0,
              lineHeight: 2,
              textAlign: 'center',
              ...rowStyle,
              backgroundColor: 'gold'
            }}
          >
            <b>{title}</b>
          </p>

          <Table bordered style={{ borderColor: '#000' }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Analysis Number</th>
                <th>System</th>
                <th>Unit</th>
                <th>Location</th>
                <th>Analysis Description</th>
                <th>Created on</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={12}>
                  <div
                    style={{
                      display: 'flex',
                      height: '40vh',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner />
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
      </RcmLayout>
    )
  }

  return (
    <RcmLayout onBack={'/cmms/rcm/dashboard'}>
      <Row style={{ margin: 15 }}>
        <SubHeader header={title} permitColor="gold" />

        <Table bordered style={{ borderColor: '#000' }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Analysis Number</th>
              <th>System</th>
              <th>Unit</th>
              <th>Location</th>
              <th>Analysis Description</th>
              <th>Created on</th>
            </tr>
          </thead>
          <tbody>
            {data?.analysis?.map((a: any, rIndex: any) => (
              <tr key={a?._id}>
                <td className="text-center">{rIndex + 1}</td>
                <td className="text-center">
                  <Link onClick={() => navigate(a?._id)}>{a?.number}</Link>
                </td>
                <td className="text-center">{a?.system}</td>
                <td>{a?.plantUnit}</td>
                <td>{a?.location}</td>
                <td>{a?.analysisDescription}</td>
                <td>{getReadableTime(a?.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </RcmLayout>
  )
}
