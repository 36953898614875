import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Box, renderData } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { SubHeader } from '../VerifyCM'
import RcmLayout from '../../components/rcm/RcmLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { Button, Input, ModalFooter, Row, Table } from 'reactstrap'
import { RcmBasicDetails } from './RcmViewStage1'

const failureModes = [
  'Abnormal instrument reading',
  'Breakdown',
  'Control-line-to-well communication',
  'Control / signal failure',
  'Sludge build-up',
  'Minor in-service problems',
  'Failure to set/retrieve',
  'Spurious high alarm level',
  'Spurious low alarm level',
  'Slippage',
  'Spurious operation',
  'Structural deficiency',
  'Failure to stop on demand',
  'Stuck',
  'Global buckling',
  'Unknown',
  'Spurious stop',
  'Vibration',
  'Very low output',
  'Well-to-control-line communication',
  'Delayed operation',
  'External leakage - fuel',
  'External leakage - process medium',
  'External leakage - utility medium',
  'Erratic output',
  'Failure to connect',
  'Failure to cut',
  'Failure in lightning protection system',
  'Faulty output frequency',
  'Faulty output voltage',
  'Failure to rotate',
  'Failure to close on demand',
  'Failure to disconnect',
  'Failure to function on demand',
  'Failure to function as intended',
  'Failure to lock/unlock',
  'Failure to open on demand',
  'Failure to regulate',
  'Failure to start on demand',
  'Failure while running',
  'High output',
  'Heating failure',
  'Insufficient heat transfer',
  'Internal leakage - process medium',
  'Internal leakage - utility medium',
  'Internal leakage',
  'Low oil supply pressure',
  'Leakage in closed position',
  'Load drop',
  'Loss of buoyancy',
  'Low output',
  'Mooring failure',
  'Noise',
  'No output',
  'Overheating',
  'Other',
  'Premature closure',
  'Parameter deviation',
  'Plugged/ choked',
  'Loss of functions on both pods',
  'Insufficient power',
  'Power/signal transmission failure'
]

const failureEffects = [
  'False alarm, faulty instrument indication',
  'Serious damage (seizure, breakage)',
  'Loss of hydraulic control fluids into the well bore',
  'No, or faulty monitoring or regulation, failure to transmit or receive commad or data',
  'Power/signal transmission failure',
  'Loose items, discoloration, dirt',
  'False alarm, premature closure/stop, unexpected operation/fails to operate as demanded',
  'Material damages (cracks, wear, fracture, corrosion, decay)',
  'Too little information to define a failure mode',
  'Unexpected shutdown',
  'Abnormal/excessive vibration',
  'Serious damage (seizure, breakage)',
  'Oscillating, hunting, instability',
  'External leakage - util- Lubricant, cooling water',
  "Failure to start on de- Doesn't start on demand",
  'Overspeed/output above acceptance',
  'Cooling/heating below acceptance and/or heat transfer too low',
  'Leakage internally process or utility fluids',
  'Low oil supply pressure',
  'Delivery/output/torque/performance below acceptance',
  'Abnormal or excessive noise',
  'Machine parts, exhaust, cooling water, etc.',
  'Failure mode(s) not covered above',
  'Monitored parameter exceeding limits, e.g. High/Low alarm',
  'Flow restriction due to contamination, objects, wax etc.',
  'Lack of or too low power supply',
  'Power/signal transmission failure'
]

export default function RcmViewStage4() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch, isLoading }: any = useQuery('fetchRcm', fetchRcm)
  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})

  const currentAnalysisData = data?.analysis
  const hasCurrentRcm = currentAnalysisData?.number

  const [equipment, setEquipment]: any = useState(
    currentAnalysisData?.equipmentList
  )

  useEffect(
    () => {
      setEquipment(currentAnalysisData?.equipmentList)
    },
    data?.analysis?.equipmentList
  )

  const categorizedEquipment = currentAnalysisData?.categorizedEquipment || {}

  const [entries, setEntries] = useState(
    categorizedEquipment &&
      Object.keys(categorizedEquipment).reduce((acc: any, key) => {
        acc[key] = [
          { functionalFailure: '', failureMode: '', failureEffect: '' }
        ]
        return acc
      }, {})
  )

  const handleInputChange = (
    categoryKey: any,
    index: any,
    field: any,
    value: any
  ) => {
    const newEntries = [...entries[categoryKey]]
    newEntries[index][field] = value
    setEntries((prev: any) => ({ ...prev, [categoryKey]: newEntries }))
  }

  const handleAddEntry = (categoryKey: any) => {
    const newEntry = {
      systemName: '',
      systemNumber: '',
      equipmentNumber: '',
      criticality: '',
      failures: [{ functionalFailure: '', failureMode: '', failureEffect: '' }]
    }
    setEntries((prev: any) => ({
      ...prev,
      [categoryKey]: prev[categoryKey]
        ? [...prev[categoryKey], newEntry]
        : [newEntry]
    }))
  }

  const handleRemoveEntry = (categoryKey: any, index: any) => {
    setEntries((prev: any) => {
      const updatedEntries = { ...prev }

      if (updatedEntries[categoryKey]) {
        updatedEntries[categoryKey] = updatedEntries[categoryKey].filter(
          (_: any, i: any) => i !== index
        )
      }

      return updatedEntries
    })
  }

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15 }}>
        <SubHeader
          header={`${hasCurrentRcm ? currentAnalysisData?.number : ''}`}
          permitColor="gold"
        />
        <Spacer height={20} />
        <RcmBasicDetails data={currentAnalysisData} />
        <Spacer height={20} />
        <Box heading="Functions">
          {Object.keys(categorizedEquipment)?.map((k: any) => (
            <>
              <h5>{k}</h5>
              <p>
                Associated Tags :{' '}
                {categorizedEquipment?.[k]
                  ?.map((c: any) => c?.equipmentNumber)
                  .join(', ')}
              </p>
              <Table bordered style={{ borderColor: '#000' }}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Functional Failure</th>
                    <th>Failure modes</th>
                    <th>Failure Effect</th>
                    <td>Remove</td>
                  </tr>
                </thead>
                <tbody>
                  {entries[k]?.map((entry: any, index: any) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Input
                          type="text"
                          value={entry.functionalFailure}
                          onChange={(e) =>
                            handleInputChange(
                              k,
                              index,
                              'functionalFailure',
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="select"
                          value={entry.failureMode}
                          onChange={(e) =>
                            handleInputChange(
                              k,
                              index,
                              'failureMode',
                              e.target.value
                            )
                          }
                        >
                          <option>Select</option>
                          {failureModes?.map((f) => (
                            <option key={f}>{f}</option>
                          ))}
                        </Input>
                      </td>
                      <td>
                        <Input
                          type="select"
                          value={entry.failureEffect}
                          onChange={(e) =>
                            handleInputChange(
                              k,
                              index,
                              'failureEffect',
                              e.target.value
                            )
                          }
                        >
                          <option>Select</option>
                          {failureEffects?.map((f) => (
                            <option key={f}>{f}</option>
                          ))}
                        </Input>
                      </td>
                      <td>
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() => handleRemoveEntry(k, index)}
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Button color="warning" onClick={() => handleAddEntry(k)}>
                Add Another Entry
              </Button>
              <Spacer height={20} />
            </>
          ))}
        </Box>
        {currentAnalysisData?.status === 'Stage4' && (
          <ModalFooter>
            <Button color="warning" onClick={() => setSubmit(true)}>
              Submit
            </Button>
          </ModalFooter>
        )}
        <SubmitModalWithForm
          isOpen={submit}
          onClose={(val: any) => {
            setSubmit(false)
          }}
          onSubmit={async (e: any) => {
            e?.preventDefault()
            // console.log(entries)
            const categorizedFailues = Object.keys(categorizedEquipment)?.map(
              (k: any) => ({
                name: k,
                failures: entries[k],
                tags: categorizedEquipment[k]
              })
            )
            const body: any = {
              ...currentAnalysisData,
              failuresModes: categorizedFailues,
              status: 'Stage5'
            }

            const res = await makeAuthenticatedRequest(
              `${rcmApi}/rcmAnalysis/${id}`,
              'PATCH',
              body,
              accounts,
              instance
            )

            if (res.status === 'success') {
              await refetch()
              setResult(res?.analysis)
            }

            return res.status
          }}
          sucessView={<p>RCM Number: {result?.number}</p>}
          size="xs"
          header="Functional Failures"
        />
      </Row>
    </RcmLayout>
  )
}
