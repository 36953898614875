import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi, workOrderAPI } from '../../components/serverurl'
import { useQuery } from 'react-query'
import RcmLayout from '../../components/rcm/RcmLayout'
import { Button, Col, ModalFooter, Row, Table } from 'reactstrap'
import { SubHeader } from '../VerifyCM'
import Spacer from '../../components/Spacer'
import CustomLabel from '../../components/InputFields/CustomLabel'
import DropDownFetch from '../../components/InputFields/DropDownFetch'
import { useState } from 'react'
import { Box, renderData } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'

export const RcmBasicDetails = ({ data }: { data: any }) => {
  const rcmDetails: any = {
    Unit: data?.unit || data?.plantUnit,
    System: data?.system,
    Location: data?.location,
    'Analysis Description': data?.analysisDescription,
    'Created On': getReadableTime(data?.createdAt)
  }
  return (
    <>
      <Box heading="RCM Basic Details">
        {Object.keys(rcmDetails)?.map((k: any) => renderData(k, rcmDetails[k]))}
      </Box>

      <Box heading="Team Members">
        <Table bordered className="min-vh-50" style={{ borderColor: '#000' }}>
          <thead>
            <tr>
              {['S.No', 'Name', 'Emp No', 'Department', 'Designation']?.map(
                (h: any) => <th key={h}>{h}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {data?.team?.map((e: any, Eindex: number) => (
              <tr key={e?.value}>
                <td>{Eindex + 1}</td>
                <td>{e?.givenName}</td>
                <td>{e?.employeeId}</td>
                <td>{e?.department}</td>
                <td>{e?.jobTitle}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>
    </>
  )
}

export default function RcmViewStage1() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchRcm', fetchRcm)
  const [equipment, setEquipment]: any = useState()
  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})

  const currentAnalysisData = data?.analysis
  const hasCurrentRcm = currentAnalysisData?.number

  const navigate = useNavigate()

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15 }}>
        <SubHeader
          header={`Analysis Number : ${
            hasCurrentRcm ? currentAnalysisData?.number : ''
          }`}
          permitColor="gold"
        />
        <Spacer height={20} />
        <RcmBasicDetails data={currentAnalysisData} />
        <Col xs="5">
          <CustomLabel label="Equipment / Component / Maintainable Item" />
          <DropDownFetch
            name="equipment"
            value={equipment}
            setValue={setEquipment}
            url={`${workOrderAPI}/filters`}
            isMulti
            needSelect={false}
          />
        </Col>
        <Col xs="5"></Col>
        <Col
          xs="2"
          style={{
            display: 'flex',
            justifyContent: 'end',
            height: 40,
            alignItems: 'center'
          }}
        >
          <Button
            color="warning"
            onClick={() => {
              localStorage.setItem('rcmId', `${id}`)
              navigate('/cmms/rcm/systemBoundary')
            }}
          >
            Select from P & IDs
          </Button>
        </Col>
        <Spacer height={20} />
        <Col xs={12}>
          <Box heading="Selected Equipment Details">
            <Table
              bordered
              className="min-vh-50"
              style={{ borderColor: '#000' }}
            >
              <thead>
                <tr>
                  {[
                    'S.No',
                    'System Name',
                    'System Number',
                    'Equipment Number',
                    'Criticality'
                  ]?.map((h: any) => <th key={h}>{h}</th>)}
                </tr>
              </thead>
              <tbody>
                {equipment?.map((e: any, Eindex: number) => (
                  <tr key={e?.value}>
                    <td>{Eindex + 1}</td>
                    <td>{e?.description}</td>
                    <td>{e?.function?.split('.')?.[0]}</td>
                    <td>{e?.value}</td>
                    <td>{e?.criticality}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
        </Col>
        {currentAnalysisData?.status === 'Stage2' && (
          <ModalFooter>
            <Button
              color="warning"
              onClick={() => setSubmit(true)}
              // disabled={activeStep === steps?.length}
            >
              Submit
            </Button>
          </ModalFooter>
        )}
        <SubmitModalWithForm
          isOpen={submit}
          onClose={(val: any) => {
            setSubmit(false)
          }}
          onSubmit={async (e: any) => {
            e?.preventDefault()
            const body: any = {
              equipmentList: equipment?.map((e: any) => ({
                systemNumber: e?.sytem || e?.function?.split('.')?.[0],
                systemName: e?.description,
                equipmentNumber: e?.value,
                criticality: e?.criticality
              })),
              status: 'Stage3'
            }
            const res = await makeAuthenticatedRequest(
              `${rcmApi}/rcmAnalysis/${id}`,
              'PATCH',
              body,
              accounts,
              instance
            )

            if (res.status === 'success') {
              await refetch()
              setResult(res?.analysis)
            }

            return res.status
          }}
          sucessView={<p>RCM Number: {result?.number}</p>}
          size="xs"
          header="Add Tags"
        />
      </Row>
    </RcmLayout>
  )
}
