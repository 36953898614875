import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  ModalFooter,
  Row,
  Spinner,
  Table
} from 'reactstrap'
import { useEffect, useState } from 'react'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { rcmApi } from '../../components/serverurl'
import { useMsal } from '@azure/msal-react'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { useQuery } from 'react-query'
import { Box } from '../workManagement/PmTree'
import { ViewHistory } from '../ViewCM'
import CustomLabel from '../../components/InputFields/CustomLabel'
import Spacer from '../../components/Spacer'

export default function CreateRiskMatrix() {
  const { accounts, instance } = useMsal()

  const fetchRiskMatrix = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/riskMatrix`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch, isLoading }: any = useQuery(
    'fetchRiskMatrix',
    fetchRiskMatrix
  )
  const riskMatrixData = data?.riskMatrix?.[0]
  const isExisting = riskMatrixData?._id
  const history = data?.history

  const [rows, setRows] = useState(riskMatrixData?.rows || 1)
  const [columns, setColumns] = useState(riskMatrixData?.columns || 1)
  const [rowHeader, setRowHeader]: any = useState('')
  const [columnHeader, setColumnHeader]: any = useState('')
  const [riskLevel, setRiskLevel]: any = useState(
    riskMatrixData?.riskToleranceLevel || 16
  )
  const [cellData, setCellData]: any = useState(
    { ...riskMatrixData?.cells } || {}
  )
  const [dropdownOpen, setDropdownOpen]: any = useState({})
  const [modal, setModal]: any = useState(false)

  useEffect(() => {
    if (riskMatrixData) {
      setRows(riskMatrixData.rows)
      setColumns(riskMatrixData.columns)
      setRowHeader(riskMatrixData?.rowHeader)
      setColumnHeader(riskMatrixData?.columnHeader)
      const cellObject = riskMatrixData.cells.reduce((acc: any, cell: any) => {
        acc[cell.position] = cell
        return acc
      }, {})

      setCellData(cellObject)
    }
  }, [riskMatrixData])

  if (isLoading) {
    return (
      <ProtectedLayout title="CREATE RISK MATRIX" onBack="/cmms/rcm/admin">
        <Row
          style={{
            margin: 15,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh'
          }}
        >
          <Spinner />
        </Row>
      </ProtectedLayout>
    )
  }

  const toggleDropdown = (rowIndex: any, colIndex: any) => {
    setDropdownOpen((prevState: any) => ({
      ...prevState,
      [`${rowIndex}-${colIndex}`]: !prevState[`${rowIndex}-${colIndex}`]
    }))
  }

  const handleSelectColor = (rowIndex: any, colIndex: any, color: any) => {
    const positionKey = `R${rowIndex + 1}C${colIndex + 1}`
    setCellData({
      ...cellData,
      [positionKey]: { position: positionKey, backgroundColor: color }
    })
  }

  const handleInputChange = (rowIndex: any, colIndex: any, value: any) => {
    const positionKey = `R${rowIndex + 1}C${colIndex + 1}`
    setCellData({
      ...cellData,
      [positionKey]: { ...cellData[positionKey], value: value }
    })
  }

  return (
    <ProtectedLayout title="CREATE RISK MATRIX" onBack="/cmms/rcm/admin">
      <Row style={{ margin: 15 }}>
        <Col xs={12} md={12} className="mb-3">
          <Button color="warning" onClick={() => setRows(rows + 1)}>
            Add Row
          </Button>{' '}
          <Button color="warning" onClick={() => setColumns(columns + 1)}>
            Add Column
          </Button>{' '}
          <Button color="danger" onClick={() => setRows(rows - 1)}>
            Delete Row
          </Button>{' '}
          <Button color="danger" onClick={() => setColumns(columns - 1)}>
            Delete Column
          </Button>
        </Col>
        <Col xs={3}>
          <CustomLabel label="Row Header" />
          <Input
            type="text"
            value={rowHeader}
            onChange={(e: any) => setRowHeader(e?.target?.value)}
          />
        </Col>
        <Col xs={3}>
          <CustomLabel label="Column Header" />
          <Input
            type="text"
            value={columnHeader}
            onChange={(e: any) => setColumnHeader(e?.target?.value)}
          />
        </Col>
        <Col xs={3}>
          <CustomLabel label="Risk Tolerance Level" />
          <Input
            type="text"
            value={riskLevel}
            onChange={(e: any) => setRiskLevel(e?.target?.value)}
          />
        </Col>
        <Col xs={6}></Col>
        <Spacer height={20} />
        <Col xs={12} md={9}>
          <h4>Edit Matrix Preview</h4>
          <Table bordered style={{ borderColor: '#000' }}>
            <tbody>
              {Array.from({ length: rows }).map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {Array.from({ length: columns }).map((_, colIndex) => {
                    const positionKey = `R${rowIndex + 1}C${colIndex + 1}`
                    const cellValue = cellData[positionKey]?.value || ''
                    const cellStyle = cellData[positionKey]
                      ? {
                          backgroundColor: cellData[positionKey].backgroundColor
                        }
                      : {}

                    return (
                      <td key={colIndex} style={cellStyle}>
                        <>
                          Position: ({rowIndex},{colIndex})
                        </>
                        <Dropdown
                          isOpen={
                            dropdownOpen[`${rowIndex + 1}-${colIndex + 1}`] ||
                            false
                          }
                          toggle={() =>
                            toggleDropdown(rowIndex + 1, colIndex + 1)
                          }
                          direction="down"
                        >
                          <DropdownToggle caret color="light">
                            Select Color
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() =>
                                handleSelectColor(rowIndex, colIndex, '#d9534f')
                              }
                            >
                              Red
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleSelectColor(rowIndex, colIndex, '#5cb85c')
                              }
                            >
                              Green
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleSelectColor(rowIndex, colIndex, '#f0ad4e')
                              }
                            >
                              Orange
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleSelectColor(rowIndex, colIndex, '#fff44f')
                              }
                            >
                              Yellow
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleSelectColor(rowIndex, colIndex, '#0275d8')
                              }
                            >
                              Blue
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleSelectColor(rowIndex, colIndex, '#f7f7f7')
                              }
                            >
                              White
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                        <Input
                          type="text"
                          placeholder="Enter value"
                          value={cellValue}
                          onChange={(e) =>
                            handleInputChange(
                              rowIndex,
                              colIndex,
                              e.target.value
                            )
                          }
                          style={{ marginTop: '5px' }}
                        />
                      </td>
                    )
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        {/* Preview */}
        <Col xs={4} md={3}>
          <h4>Risk Matrix Preview</h4>
          <Table bordered style={{ borderColor: '#000' }}>
            <tbody>
              <tr>
                <td className="text-center fw-bold">{rowHeader}</td>
                <td className="text-center fw-bold" colSpan={columns}>
                  {columnHeader}
                </td>
              </tr>
              {Array.from({ length: rows }).map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {Array.from({ length: columns }).map((_, colIndex) => {
                    const positionKey = `R${rowIndex + 1}C${colIndex + 1}`
                    const cellStyle = cellData[positionKey]
                      ? {
                          backgroundColor: cellData[positionKey].backgroundColor
                        }
                      : {}
                    const cellValue = cellData[positionKey]?.value || ''

                    return (
                      <td
                        key={colIndex}
                        style={{
                          ...cellStyle,
                          height: '3vh',
                          width: '1vw',
                          textAlign: 'center',
                          padding: '10px'
                        }}
                      >
                        {cellValue}
                      </td>
                    )
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col xs={12}>
          <Box heading="Time Stamps">
            <ViewHistory data={history} showTitle={false} />
          </Box>
        </Col>
        <ModalFooter>
          <Button color="warning" onClick={() => setModal(true)}>
            {isExisting ? 'Update' : 'Create'}
          </Button>
        </ModalFooter>
      </Row>
      <SubmitModalWithForm
        isOpen={modal}
        onClose={(val: any) => {
          setModal(false)
        }}
        onSubmit={async (e: any) => {
          const method = isExisting ? 'PATCH' : 'POST'
          const body: any = {
            rows: rows,
            columns: columns,
            cells: cellData,
            rowHeader,
            columnHeader,
            riskToleranceLevel: riskLevel
          }
          e?.preventDefault()
          const res = await makeAuthenticatedRequest(
            `${rcmApi}/riskMatrix?id=${riskMatrixData?._id}`,
            method,
            body,
            accounts,
            instance
          )

          if (res.status === 'success') {
            await refetch()
          }

          return res.status
        }}
        size="xs"
        header={`${isExisting ? 'Update' : 'Create'} Risk Matrix`}
      />
    </ProtectedLayout>
  )
}
