import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import CustomLabel from '../../components/InputFields/CustomLabel'
import RcmLayout from '../../components/rcm/RcmLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { Box, Link } from '../workManagement/PmTree'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { mtbfOptions } from './RcmViewStage5'
import { rcmApi } from '../../components/serverurl'
import { RcmBasicDetails } from './RcmViewStage1'
import { SubHeader } from '../VerifyCM'
import { Text } from '../../components/RiskAssesmentPreview'
import { yesNoOptions } from '../eptw/VerifyPermit'

const taskInterval: any = {
  Daily: 365,
  Weekly: 52,
  Monthly: 12,
  '3 Monthly': 4,
  '6 Monthly': 2,
  '1 Yearly': 1,
  '2 Yearly': 0.6,
  '3 Yearly': 0.4,
  '5 Yearly': 0.25
}

const DecissionTree = ({
  handleChange,
  decision
}: {
  handleChange: any
  decision: any
}) => {
  const [isOpen, setIsOpen]: any = useState(false)
  const [value, setValue]: any = useState()
  const [id, setId]: any = useState()
  const [condition, setCondition]: any = useState()
  const [options, setOptions]: any = useState()

  const ViewOption = ({
    header,
    text,
    options,
    name
  }: {
    header: any
    text: any
    options: any
    name: any
  }) => {
    const wrapper: any = {
      border: '1px solid #000',
      padding: 5,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center'
    }
    const styles = `.box:hover {
      background-color: lightblue;
   }`
   
    return (
      <div style={{ width: '30vw' }}>
        <style>{styles}</style>
        <div
          className="box"
          onClick={() => {
            const filteredOptions = [...yesNoOptions, ...noOptions]?.filter(
              (o: any) => o?.header === header
            )
            setOptions(filteredOptions)
          }}
        >
          <div style={wrapper}>
            <p style={{ borderBottom: '1px solid #000', fontWeight: 600 }}>
              {header}
            </p>
            <CustomLabel label={text} />
          </div>
        </div>
        <Spacer height={10} />
        <div style={wrapper}>
          <Input
            type="select"
            value={value}
            style={{ width: '25vw' }}
            onChange={(e: any) => {
              setValue(e?.target?.value)
              const option = options?.filter(
                (a: any) => a?.key === e?.target?.value
              )?.[0]?.value
              setId(option)
            }}
          >
            <option>Select</option>
            {options?.map((a: any, index: any) => (
              <option value={a?.key} key={`${a?.value}-${index}`}>
                {a?.key}
              </option>
            ))}
          </Input>
        </div>
      </div>
    )
  }


  const noOptions = [
    {
      text: 'Is there an Intolerable risk that the multiple failure could injure or kill someone?',
      options: [
        { key: 'Scheduled on condition Task', value: 'H-SE-L1-T1' },
        { key: 'Scheduled discard or restoration task', value: 'H-SE-L2-T1' },
        { key: 'Failure finding Task', value: 'H-SE-L3-T1' },
        { key: 'Redesign Necessary', value: 'H-SE-L3-T2' }
      ],
      header: 'HIDDEN - SE',
      name: 'No'
    },
    {
      text: 'IS there an intolerance risk that the multiple failure could breach a known environment standard or regulation?',
      options: [
        { key: 'Scheduled on condition Task', value: 'H-OC-L1-T1' },
        { key: 'Scheduled discard or restoration task', value: 'H-OC-L2-T1' },
        { key: 'Failure finding Task', value: 'H-OC-L3-T1' },
        { key: 'NO schedule Maintenance', value: 'H-OC-L3-T2' },
        { key: 'Redesign Necessary', value: 'H-OC-L4-T2' }
      ],
      header: 'HIDDEN - OC',
      name: 'Yes'
    },
    {
      text: 'Does the fmultiple failure have a direct adverse effect on operational capability?',
      options: [
        { key: 'Scheduled on condition Task', value: 'H-NC-L1-T1' },
        {
          key: 'Scheduled discard or restoration task',
          value: 'H-NC-L2-T1'
        },
        { key: 'Failure finding Task', value: 'H-NC-L3-T1' },
        { key: 'NO schedule Maintenance', value: 'H-NC-L3-T2' },
        { key: 'Redesign Necessary', value: 'H-NC-L4-T2' }
      ],
      header: 'HIDDEN - NC',
      name: 'Yes'
    }
  ]

  return (
    <>
      <Link onClick={() => setIsOpen(true)}>
        {decision ? decision : 'Perform MTA'}
      </Link>
      <Modal size="lg" isOpen={isOpen} fullscreen>
        <p
          style={{
            backgroundColor: 'gold',
            fontSize: 20,
            textAlign: 'center',
            fontWeight: 700,
            height: 40,
            paddingTop: 10,
            paddingBottom: 10
          }}
        >
          RCM Decision Tree
        </p>
        <ModalBody className="d-flex flex-column align-content-between align-items-center">
          <div
            className="d-flex flex-column align-items-center"
            style={{
              width: '40%',
              border: '1px solid #000',
              textAlign: 'center'
            }}
          >
            <CustomLabel label="Will the Loss of Function caused by this failure mode on its own become evident to the opering crew under normal circumstanace ?" />
            <div className="d-flex align-items-center">
              <Input
                type="radio"
                value="Yes"
                checked={condition === 'Yes'}
                name="a"
                onChange={(e: any) => {
                  setCondition('Yes')
                  setOptions(yesNoOptions)
                }}
              />
              <Spacer width={5} />
              <Text> Yes</Text>
              <Spacer width={10} />
              <Input
                type="radio"
                value="No"
                checked={condition === 'No'}
                name="a"
                onChange={(e: any) => {
                  setCondition('No')
                  setOptions(noOptions)
                }}
              />
              <Spacer width={5} />
              <Text>No</Text>
            </div>
          </div>
          <Spacer height={20} />
          {condition === 'Yes' && (
            <div className="d-flex justify-content-around">
              {options?.map((o: any) => (
                <ViewOption
                  key={o?.header}
                  name={o?.name}
                  text={o?.text}
                  header={o?.header}
                  options={o?.options}
                />
              ))}
            </div>
          )}
          {condition === 'No' && (
            <div className="d-flex justify-content-around">
              {options?.map((o: any) => (
                <ViewOption
                  name={o?.name}
                  text={o?.text}
                  header={o?.header}
                  options={o?.options}
                />
              ))}
            </div>
          )}
          <Spacer height={20} />
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            onClick={() => {
              handleChange(value, id)
              setIsOpen(false)
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default function RcmViewStage7() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchRcm', fetchRcm)

  const fetchRiskMatrix = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/riskMatrix`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data: riskData, isLoading }: any = useQuery(
    'fetchRiskMatrix',
    fetchRiskMatrix
  )
  const riskMatrixData = riskData?.riskMatrix?.[0]
  const riskToleranceLevel = Number(riskMatrixData?.riskToleranceLevel) || 16

  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})

  const currentAnalysisData = data?.analysis
  const hasCurrentRcm = currentAnalysisData?.number
  const riskDetails = currentAnalysisData?.riskDetails || []
  const categorizedEquipment = currentAnalysisData?.categorizedEquipment

  const [entries, setEntries] = useState(riskDetails)

  useEffect(
    () => {
      setEntries(riskDetails)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    data?.analysis?.failuresModes
  )

  const handleInputChange = (
    categoryKey: any,
    index: any,
    field: any,
    value: any
  ) => {
    const updatedEntries = [...entries[categoryKey]]
    updatedEntries[index][field] = value
    setEntries((prev: any) => ({ ...prev, [categoryKey]: updatedEntries }))
  }

  const calculateYearlyMaintenance = (
    spare: number,
    externamCost: number,
    resource1: number,
    resource2: number,
    resource3: number,
    interval: number
  ) => {
    const totalResourceCost = resource1 + resource2 + resource3 * 23
    const result = (spare + externamCost + totalResourceCost) * interval

    return result
  }

  const calcualteValue = (
    spare: number,
    externamCost: number,
    resource1: number,
    resource2: number,
    resource3: number,
    interval: number,
    economic: number,
    mtbf: number
  ) => {
    const annualCost = calculateYearlyMaintenance(
      spare,
      externamCost,
      resource1,
      resource2,
      resource3,
      interval
    )

    const totalEconomic = economic * mtbf
    const result = (totalEconomic / annualCost).toFixed(4)
    return result
  }

  const calculateNewTaskInterval = (
    spare: number,
    externamCost: number,
    resource1: number,
    resource2: number,
    resource3: number,
    interval: number,
    economic: number,
    mtbf: number,
    taskInterval: any
  ) => {
    const calcualteAssumption = (intvl: number) => {
      const assumption = calcualteValue(
        spare,
        externamCost,
        resource1,
        resource2,
        resource3,
        intvl,
        economic,
        mtbf
      )

      return Number(assumption)
    }

    const assumption = calcualteAssumption(interval)

    let newTaskInterval: any = taskInterval
    let currentAssumption = assumption
    let newAssumption: any = assumption

    if (currentAssumption < 1) {
      const dailyAs = calcualteAssumption(365)
      const weeklyAs = calcualteAssumption(52)
      const monthlyAs = calcualteAssumption(12)
      const threeMonthly = calcualteAssumption(4)
      const sixMontly = calcualteAssumption(2)
      const yearly = calcualteAssumption(1)
      const twoYearly = calcualteAssumption(0.6)
      const threeYearly = calcualteAssumption(0.4)
      const fiveYearly = calcualteAssumption(0.25)

      if (assumption > 1) {
        newTaskInterval = taskInterval
        // newAssumption = taskInterval
      } else if (dailyAs > 1) {
        newTaskInterval = 'Daily'
        newAssumption = dailyAs
      } else if (weeklyAs > 1) {
        newTaskInterval = 'Weekly'
        newAssumption = weeklyAs
      } else if (monthlyAs > 1) {
        newTaskInterval = 'Monthly'
        newAssumption = monthlyAs
      } else if (threeMonthly > 1) {
        newTaskInterval = '3 Monthly'
        newAssumption = threeMonthly
      } else if (sixMontly > 1) {
        newTaskInterval = '6 Monthly'
        newAssumption = sixMontly
      } else if (yearly > 1) {
        newTaskInterval = 'Yearly'
        newAssumption = yearly
      } else if (twoYearly > 1) {
        newTaskInterval = '2 Yearly'
        newAssumption = twoYearly
      } else if (threeYearly > 1) {
        newTaskInterval = '3 Yearly'
        newAssumption = threeYearly
      } else if (fiveYearly > 1) {
        newTaskInterval = '5 Yearly'
        newAssumption = fiveYearly
      }
    }

    // return `${newTaskInterval}-${newAssumption}`
    return `${newTaskInterval}`
  }

  const newCalculatedRatio = (
    spare: number,
    externamCost: number,
    resource1: number,
    resource2: number,
    resource3: number,
    interval: number,
    economic: number,
    mtbf: number,
    taskInterval: any
  ) => {
    const calcualteAssumption = (intvl: number) => {
      const assumption = calcualteValue(
        spare,
        externamCost,
        resource1,
        resource2,
        resource3,
        intvl,
        economic,
        mtbf
      )

      return Number(assumption)
    }

    const assumption = calcualteAssumption(interval)

    let newTaskInterval: any = interval
    let currentAssumption = assumption
    let newAssumption = assumption

    if (currentAssumption < 1) {
      const dailyAs = calcualteAssumption(365)
      const weeklyAs = calcualteAssumption(52)
      const monthlyAs = calcualteAssumption(12)
      const threeMonthly = calcualteAssumption(4)
      const sixMontly = calcualteAssumption(2)
      const yearly = calcualteAssumption(1)
      const twoYearly = calcualteAssumption(0.6)
      const threeYearly = calcualteAssumption(0.4)
      const fiveYearly = calcualteAssumption(0.25)

      if (assumption > 1) {
        newTaskInterval = taskInterval
        newAssumption = taskInterval
      } else if (dailyAs > 1) {
        newTaskInterval = 'Daily'
        newAssumption = dailyAs
      } else if (weeklyAs > 1) {
        newTaskInterval = 'Weekly'
        newAssumption = weeklyAs
      } else if (monthlyAs > 1) {
        newTaskInterval = 'Monthly'
        newAssumption = monthlyAs
      } else if (threeMonthly > 1) {
        newTaskInterval = '3 Monthly'
        newAssumption = threeMonthly
      } else if (sixMontly > 1) {
        newTaskInterval = '6 Monthly'
        newAssumption = sixMontly
      } else if (yearly > 1) {
        newTaskInterval = 'Yearly'
        newAssumption = yearly
      } else if (twoYearly > 1) {
        newTaskInterval = '2 Yearly'
        newAssumption = twoYearly
      } else if (threeYearly > 1) {
        newTaskInterval = '3 Yearly'
        newAssumption = threeYearly
      } else if (fiveYearly > 1) {
        newTaskInterval = '5 Yearly'
        newAssumption = fiveYearly
      }
    }

    // return `${newTaskInterval}-${newAssumption}`
    return `${newAssumption}`
  }
  console.log(entries)

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15, maxWidth: '87vw' }}>
        <SubHeader
          header={`${hasCurrentRcm ? currentAnalysisData?.number : ''}`}
          permitColor="gold"
        />
        <Spacer height={20} />
        <RcmBasicDetails data={currentAnalysisData} />
        <Spacer height={20} />
        <Box heading="Functions">
          {Object.keys(riskDetails).map((mode: any, modeIndex: number) => (
            <div key={modeIndex}>
              <h4>{mode}</h4>
              <p>
                <strong>Associated Tags:</strong>{' '}
                {categorizedEquipment[mode]
                  ?.map((a: any) => a?.equipmentNumber)
                  ?.join(', ')}{' '}
              </p>
              <Table
                bordered
                responsive
                hover
                style={{ borderColor: '#000', tableLayout: 'fixed' }}
              >
                <thead>
                  <tr className="text-center">
                    <th
                      style={{
                        width: '2vw'
                      }}
                    >
                      S.No
                    </th>
                    <th style={{ width: '12vw' }}>Failure modes</th>
                    <th style={{ width: '5vw' }}>Risk Score</th>
                    <th style={{ width: '12vw' }}>RCM Decision</th>
                    <th style={{ width: '8vw' }}>Decision Tree Code</th>
                    <th style={{ width: '12vw' }}>Client Decision</th>
                    <th style={{ width: '8vw' }}>Task Status</th>
                    <th style={{ width: '8vw' }}>Task Duration</th>
                    <th style={{ width: '8vw' }}>Task Interval</th>
                    <th style={{ width: '8vw' }}>
                      Extrenal Service Cost (USD)
                    </th>
                    <th style={{ width: '8vw' }}>Spare Cost (USD)</th>
                    <th style={{ width: '12vw' }}>Discipline 1</th>
                    <th style={{ width: '8vw' }}>Hours</th>
                    <th style={{ width: '12vw' }}>Discipline 2</th>
                    <th style={{ width: '8vw' }}>Hours</th>
                    <th style={{ width: '12vw' }}>Discipline 3</th>
                    <th style={{ width: '8vw' }}>Hours</th>
                    <th style={{ width: '8vw' }}>Yearly Economic Value</th>
                    <th
                      style={{
                        width: '8vw',
                        position: 'sticky',
                        backgroundColor: 'white',
                        zIndex: 1
                      }}
                    >
                      Yealy maintenance Cost
                    </th>

                    <th style={{ width: '8vw' }}>Ratio</th>
                    <th style={{ width: '8vw' }}>New Task Interval</th>
                    <th style={{ width: '8vw' }}>New Calculated Ratio</th>
                    <th style={{ width: '5vw' }}>New Health Score</th>
                    <th style={{ width: '5vw' }}>New Environment Score</th>
                    <th style={{ width: '5vw' }}>New Economic Score</th>
                    <th style={{ width: '5vw' }}>New Risk Score</th>
                  </tr>
                </thead>
                <tbody>
                  {riskDetails[mode]?.map(
                    (entry: any, index: number) =>
                      Number(entry.riskScore) > riskToleranceLevel && (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{entry.failureMode}</td>
                          <td style={{ textAlign: 'center' }}>
                            {entry.riskScore}
                          </td>
                          <td
                            style={{
                              verticalAlign: 'middle',
                              textAlign: 'center'
                            }}
                          >
                            <>
                              <DecissionTree
                                decision={entry?.rcmDecision}
                                handleChange={async (
                                  decision: any,
                                  decisionCode: any
                                ) => {
                                  await handleInputChange(
                                    mode,
                                    index,
                                    'rcmDecision',
                                    `${decision}`
                                  )
                                  await handleInputChange(
                                    mode,
                                    index,
                                    'rcmDecisionCode',
                                    `${decisionCode}`
                                  )
                                }}
                              />
                            </>
                          </td>
                          <td className="text-center">
                            {entry?.rcmDecisionCode}
                          </td>
                          <td>
                            <Input
                              type="select"
                              onClick={(e: any) =>
                                handleInputChange(
                                  mode,
                                  index,
                                  'clientDecision',
                                  e?.target?.value
                                )
                              }
                            >
                              <option>Select</option>
                              {[
                                'Scheduled on condition Task',
                                'Scheduled discard or restoration task',
                                'Combination of task',
                                'Redesign Necessary',
                                'Failure finding Task'
                              ]?.map((a: any) => <option key={a}>{a}</option>)}
                            </Input>
                          </td>
                          <td>
                            <Input
                              type="select"
                              onClick={(e: any) =>
                                handleInputChange(
                                  mode,
                                  index,
                                  'taskStatus',
                                  e?.target?.value
                                )
                              }
                            >
                              <option>Select</option>
                              {['EXISTING', 'NEW']?.map((a: any) => (
                                <option key={a}>{a}</option>
                              ))}
                            </Input>
                          </td>
                          <td>
                            <Input
                              type="text"
                              onChange={(e: any) =>
                                handleInputChange(
                                  mode,
                                  index,
                                  'taskDuration',
                                  e?.target?.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <Input
                              type="select"
                              onChange={(e: any) =>
                                handleInputChange(
                                  mode,
                                  index,
                                  'taskInterval',
                                  e?.target?.value
                                )
                              }
                            >
                              <option>Select</option>
                              {[
                                'Daily',
                                'Weekly',
                                'Monthly',
                                '3 Monthly',
                                '6 Monthly',
                                '1 Yearly',
                                '2 Yearly',
                                '3 Yearly',
                                '5 Yearly'
                              ]?.map((a: any) => <option key={a}>{a}</option>)}
                            </Input>
                          </td>
                          <td>
                            <Input
                              type="text"
                              onChange={(e: any) =>
                                handleInputChange(
                                  mode,
                                  index,
                                  'externalCost',
                                  e?.target?.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              onChange={(e: any) =>
                                handleInputChange(
                                  mode,
                                  index,
                                  'spareCost',
                                  e?.target?.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <Input
                              type="select"
                              onClick={(e: any) =>
                                handleInputChange(
                                  mode,
                                  index,
                                  'discipline1',
                                  e?.target?.value
                                )
                              }
                            >
                              <option>Select</option>
                              {[
                                'Electrical Technician',
                                'Mechanical Technician',
                                'Instrument technician',
                                'Electrical Supervisor',
                                'Mechanical Supervisor',
                                'Instrument Supervisor'
                              ]?.map((a: any) => <option key={a}>{a}</option>)}
                            </Input>
                          </td>
                          <td>
                            <Input
                              type="text"
                              onChange={(e: any) =>
                                handleInputChange(
                                  mode,
                                  index,
                                  'discipline1Hours',
                                  e?.target?.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <Input
                              type="select"
                              onClick={(e: any) =>
                                handleInputChange(
                                  mode,
                                  index,
                                  'discipline2',
                                  e?.target?.value
                                )
                              }
                            >
                              <option>Select</option>
                              {[
                                'Electrical Technician',
                                'Mechanical Technician',
                                'Instrument technician',
                                'Electrical Supervisor',
                                'Mechanical Supervisor',
                                'Instrument Supervisor'
                              ]?.map((a: any) => <option key={a}>{a}</option>)}
                            </Input>
                          </td>
                          <td>
                            <Input
                              type="text"
                              onChange={(e: any) =>
                                handleInputChange(
                                  mode,
                                  index,
                                  'discipline2Hours',
                                  e?.target?.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <Input
                              type="select"
                              onClick={(e: any) =>
                                handleInputChange(
                                  mode,
                                  index,
                                  'discipline3',
                                  e?.target?.value
                                )
                              }
                            >
                              <option>Select</option>
                              {[
                                'Electrical Technician',
                                'Mechanical Technician',
                                'Instrument technician',
                                'Electrical Supervisor',
                                'Mechanical Supervisor',
                                'Instrument Supervisor'
                              ]?.map((a: any) => <option key={a}>{a}</option>)}
                            </Input>
                          </td>
                          <td>
                            <Input
                              type="text"
                              onChange={(e: any) =>
                                handleInputChange(
                                  mode,
                                  index,
                                  'discipline3Hours',
                                  e?.target?.value
                                )
                              }
                            />
                          </td>
                          <td>
                            {Number(entry?.economicEffect) ||
                              0 * mtbfOptions[entry?.economicEffect] ||
                              1}
                          </td>
                          <td>
                            {entry?.yearlyMaintenance}
                            <br />
                            <Link
                              onClick={() => {
                                const result = calculateYearlyMaintenance(
                                  Number(entry?.spareCost) || 0,
                                  Number(entry?.externalCost) || 0,
                                  Number(entry?.discipline1Hours) || 0,
                                  Number(entry?.discipline2Hours) || 0,
                                  Number(entry?.discipline3Hours) || 0,
                                  taskInterval[entry?.taskInterval] || 52
                                )
                                handleInputChange(
                                  mode,
                                  index,
                                  'yearlyMaintenance',
                                  result
                                )
                              }}
                            >
                              Show Values
                            </Link>
                          </td>
                          <td>
                            {entry?.ratio}
                            <br />
                            <Link
                              onClick={() => {
                                const result = calcualteValue(
                                  Number(entry?.spareCost) || 0,
                                  Number(entry?.externalCost) || 0,
                                  Number(entry?.discipline1Hours) || 0,
                                  Number(entry?.discipline2Hours) || 0,
                                  Number(entry?.discipline3Hours) || 0,
                                  taskInterval[entry?.taskInterval] || 52,
                                  Number(entry?.economicEffect) || 0,
                                  mtbfOptions[entry?.economicEffect] || 1
                                )

                                handleInputChange(mode, index, 'ratio', result)
                              }}
                            >
                              Show Values
                            </Link>
                            {}
                          </td>
                          <td>
                            {entry?.newTaskInterval}
                            <br />
                            <Link
                              onClick={() => {
                                const result = calculateNewTaskInterval(
                                  Number(entry?.spareCost) || 0,
                                  Number(entry?.externalCost) || 0,
                                  Number(entry?.discipline1Hours) || 0,
                                  Number(entry?.discipline2Hours) || 0,
                                  Number(entry?.discipline3Hours) || 0,
                                  taskInterval[entry?.taskInterval] || 52,
                                  Number(entry?.economicEffect) || 0,
                                  mtbfOptions[entry?.economicEffect] || 1,
                                  entry?.taskInterval
                                )
                                handleInputChange(
                                  mode,
                                  index,
                                  'newTaskInterval',
                                  result
                                )
                              }}
                            >
                              Show Values
                            </Link>
                          </td>
                          <td>
                            {entry?.newRatio}
                            <br />
                            <Link
                              onClick={() => {
                                const result = newCalculatedRatio(
                                  Number(entry?.spareCost) || 0,
                                  Number(entry?.externalCost) || 0,
                                  Number(entry?.discipline1Hours) || 0,
                                  Number(entry?.discipline2Hours) || 0,
                                  Number(entry?.discipline3Hours) || 0,
                                  taskInterval[entry?.taskInterval] || 52,
                                  Number(entry?.economicEffect) || 0,
                                  mtbfOptions[entry?.economicEffect] || 1,
                                  entry?.taskInterval
                                )

                                handleInputChange(
                                  mode,
                                  index,
                                  'newRatio',
                                  result
                                )
                              }}
                            >
                              Show Values
                            </Link>
                          </td>

                          <td>
                            {entry?.newHealthScore}
                            <br />
                            <Link
                              onClick={() => {
                                console.log(entry?.newTaskInterval)

                                const calInterval = (interval: string) => {
                                  const exteme: string[] = [
                                    'Daily',
                                    'Weekly',
                                    'Monthly',
                                    '3 Monthly'
                                  ]
                                  const high = ['6 Monthly']
                                  const medium = [
                                    '1 Yearly',
                                    '2 Yearly',
                                    '3 Yearly'
                                  ]
                                  const low = ['5 Yearly']

                                  if (exteme?.includes(interval)) {
                                    return 'Extreme'
                                  } else if (high?.includes(interval)) {
                                    return 'High'
                                  } else if (medium?.includes(interval)) {
                                    return 'Medium'
                                  } else if (low?.includes(interval)) {
                                    return 'Low'
                                  }
                                }

                                const newInterval: any = calInterval(
                                  entry?.newTaskInterval
                                )
                                const consequence = entry?.healthSafety
                                const riskValues: any = {
                                  Extreme: [11, 16, 20, 23, 25],
                                  High: [7, 12, 17, 21, 24],
                                  Medium: [4, 8, 13, 18, 22],
                                  Low: [2, 5, 9, 14, 19],
                                  Negligible: [1, 3, 6, 10, 15]
                                }

                                const conseqencePosition: any = {
                                  'Multiple Fatality': 4,
                                  'Single Fatality': 3,
                                  'Major Injury': 2,
                                  'Minor Injury': 1,
                                  'Slight Injury': 0
                                }

                                console.log(newInterval, consequence)

                                const possibleRiskScores =
                                  riskValues[newInterval]
                                const newRisk =
                                  possibleRiskScores[
                                    conseqencePosition[consequence]
                                  ]

                                handleInputChange(
                                  mode,
                                  index,
                                  'newHealthScore',
                                  newRisk
                                )
                              }}
                            >
                              Show Values
                            </Link>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </Table>
            </div>
          ))}
        </Box>
        {currentAnalysisData?.status === 'Stage6' && (
          <ModalFooter>
            <Button color="warning" onClick={() => setSubmit(true)}>
              Submit
            </Button>
          </ModalFooter>
        )}

        <SubmitModalWithForm
          isOpen={submit}
          onClose={(val: any) => {
            setSubmit(false)
          }}
          onSubmit={async (e: any) => {
            e?.preventDefault()

            const body: any = {
              ...currentAnalysisData,
              riskDetails: entries,
              status: 'Stage6'
            }

            const res = await makeAuthenticatedRequest(
              `${rcmApi}/rcmAnalysis/${id}`,
              'PATCH',
              body,
              accounts,
              instance
            )

            if (res.status === 'success') {
              await refetch()
              setResult(res?.analysis)
            }

            return res.status
          }}
          sucessView={<p>RCM Number: {result?.number}</p>}
          size="xs"
          header="Failure Modes"
        />
      </Row>
    </RcmLayout>
  )
}
